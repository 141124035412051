import React from 'react';
import { Calendar as ReactCalendar } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './calendar.css';
import solved_pyramini_icon from '../../images/solved_pyramini_icon.png'; // Tell webpack this JS file uses this image
import unsolved_pyramini_icon from '../../images/unsolved_pyramini_icon.png'; // Tell webpack this JS file uses this image
import unreleased_pyramini_icon from '../../images/unreleased_pyramini_icon.png'; // Tell webpack this JS file uses this image
import { dailyPyramids } from '../../app_components/utils/word_pyramid_solutions';
import useDateIndex from '../../hooks/useDateIndex';
import { Cookie } from '../utils/utils'

interface CalendarProps {
  toggleModal: () => void;
  changeWordPyramid: (dayIndex: number) => void,
  currentPyramidDateIndex: number,
}

export function Calendar(props: CalendarProps) {
  const { getDayIndex } = useDateIndex();

  const todaysDayIndex = getDayIndex();

  function getDayIndexFromPreviousDate(previousDate: Date): number {
    return todaysDayIndex - getNumberOfDaysBeforeToday(previousDate);
  }

  function openPyraminiForDate(date: Date) {
    props.changeWordPyramid(getDayIndexFromPreviousDate(date));
    props.toggleModal();
    // console.log(getNumberOfDaysBeforeToday(date));
  }

  function getPyraminiIcon(calendarDate: Date) {
    const dateIndex = getDayIndexFromPreviousDate(calendarDate);

    if (dateIndex > todaysDayIndex) {
      return unreleased_pyramini_icon;
    }

    if (dateIndex < 0) {
      return unsolved_pyramini_icon;
    }

    // If no pyramini has been released for this day, show the unreleased icon.
    const wordPyramidSolution = dailyPyramids[dateIndex];
    if (wordPyramidSolution === undefined) {
      return unreleased_pyramini_icon;
    }

    const dateSolutionHash = wordPyramidSolution.getHash();

    if (window.localStorage.getItem(Cookie.PAST_SOLUTIONS)?.includes(dateSolutionHash)) {
      return solved_pyramini_icon;
    }

    return unsolved_pyramini_icon;
  }

  function getSelectedDate(): Date {
    const dayDifference = todaysDayIndex - props.currentPyramidDateIndex;
    return subtractDaysFromDate(new Date(), dayDifference);
  }

  function subtractDaysFromDate(date: Date, daysToSubtract: number) {
    date.setDate(date.getDate() - daysToSubtract);
    return date;
  }

  return (
    <div className="calendar-container">
      <ReactCalendar defaultValue={getSelectedDate()} maxDate={new Date()} minDate={new Date(2022, 11, 21)} className='pyramini-calendar'
        tileContent={({ activeStartDate, date, view }) => view === "month" ? <img src={getPyraminiIcon(date)} alt="puzzle icon" width='30px' /> : <></>}
        onChange={(value) => { openPyraminiForDate(value as Date) }}
      />
    </div>
  );
};

function getNumberOfDaysBeforeToday(previousDate: Date): number {
  var diff = Date.now() - previousDate.getTime();
  return Math.ceil(diff / (1000 * 3600 * 24)) - 1;
}