import {WordPyramidRowDataObject} from "./word_pyramid_classes"

const lowercaseLetters = "abcdefghijklmnopqrstuvwxyz";

export function isDirection(keyEvent: string) {
	return keyEvent === "ArrowLeft" || keyEvent === "ArrowRight" || keyEvent === "ArrowDown" || keyEvent === "ArrowUp";
}

export function isAlphabetic(keyEvent: string) {
  return (lowercaseLetters + lowercaseLetters.toUpperCase()).includes(keyEvent)
}

// Return the index of the first empty value, or the last index if none are empty
export function findFirstEmptyIndex(row: WordPyramidRowDataObject): number {
  const rowValues = row.getValues();
  for (let i = 0; i < rowValues.length; i++) {
    if (rowValues[i] === EMPTY_LETTER) {
      return i;
    }
  }
  return rowValues.length - 1;
}

export const EMPTY_LETTER = " ";
export const DELETE = "<";

export const PAST_SOLUTION_DELIMETER = "&";
export const PAST_SOLUTION_CONTENT_DELIMETER = "=";


export enum SelectionType {
  LETTER,
  WORD,
  PUZZLE,
}

export enum Cookie {
  HASH = "wordPyramidSolutionHash", 
  CURRENT_PYRAMID = "currentPyramid",
  START_TIME = "startTime",
  SOLVE_TIME = "solveTime",
  IS_GAME_OVER = "isGameOver", 
  PAST_SOLUTIONS = "pastSolutions"
}