import React from "react";
import "./modal.css";
import Modal from "./modal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faEnvelope, faHammer } from '@fortawesome/free-solid-svg-icons'

interface SettingsModalProps {
  isOpen: boolean;
  toggle: () => void;
}

export default function SettingsModal(props: SettingsModalProps) {
  function sendFeedbackEmail() {
    window.open("mailto:pyramini.feedback@gmail.com?subject=Pyramini Feedback", '_blank')
  }

  function sendPyraminiCreationEmail() {
    window.open("mailto:pyramini.feedback@gmail.com?subject=Pyramini Creation", '_blank')
  }

  const content = (
    <div className="settings-modal-content">
      <h3>Settings</h3>
      <SettingWithIcon 
        mainText={"Feedback"}
        secondaryText={"Something wrong? Let us know!"}
        icon={faEnvelope}
        onClick={() => {
          return sendFeedbackEmail();
        }}
      />

      <SettingWithIcon 
        mainText={"Submit your own Pyramini"}
        secondaryText={"If you build a Pyramini, we'll feature it!"}
        icon={faHammer}
        onClick={() => {
          return sendPyraminiCreationEmail();
        }}
      />
    </div>
  )

  return (
    <Modal content={content} isOpen={props.isOpen} toggle={props.toggle} />
  );
}

interface SettingBaseProps {
  leftSide: any;
  rightSide: any;
}

function SettingBase(props: SettingBaseProps) {
  return (
    <span className="setting-base">
      <div className="setting-container">
        <div className="left-side">
          {props.leftSide}
        </div>
        <div className="right-side">
          {props.rightSide}
        </div>
      </div>
      <div className="modal-section-separator"></div>
    </span>
  );
}

interface SettingWithIconProps {
  mainText: string;
  secondaryText: string;
  icon: IconProp;
  onClick: any;
}

function SettingWithIcon(props: SettingWithIconProps) {
  return (
    <SettingBase
      leftSide = {
        <div>
          <div className="main-text">{props.mainText}</div>
          <div className="secondary-text">{props.secondaryText}</div>
        </div>
      }
      rightSide = {
        <div className="icon-button" onClick={() => props.onClick()}>
          <FontAwesomeIcon icon={props.icon} />
        </div>
      }
    />
  );
}