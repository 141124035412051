import { useState, useEffect } from "react";

interface useSolveTimeProps {
  getCurrentTime: Function;
}

export default function useSolveTime(props: useSolveTimeProps) {
  const [currentTime, setCurrentTime] = useState(props.getCurrentTime());

  // If a finish time is supplied, it is taken into account. Else, the current time is used as the reference.
  const getTotalSolveTime = (startTime: string, finishTime?: string): string => {
    const timeDifference = finishTime ? getTimeDifference(startTime, finishTime) : getTimeDifference(startTime, currentTime);
    return getTimeAsString(timeDifference);
  };

  const getTimeDifference = (firstTime: string, secondTime: string): number => {
    return parseInt(secondTime) - parseInt(firstTime);
  }

  const getTimeAsString = (time: number): string => {
    if (Number.isNaN(time)) {
      return "";
    }

    if (time < 0) {
      return "";
    }

    return `${Math.floor(time / 60)}:` + `${time % 60}`.padStart(2, '0');
  }

  useEffect(() => {
    const interval = setInterval(() => setCurrentTime(props.getCurrentTime()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, [props]);

  return {
    getTotalSolveTime,
  };
}