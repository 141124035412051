import { useState } from "react";
import {Cookie} from "../app_components/utils/utils";

export function useInfoModal() {
  const userHasNotPlayedBefore = window.localStorage.getItem(Cookie.PAST_SOLUTIONS) === null;
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(userHasNotPlayedBefore);

  const toggleInfoModal = () => {
    setIsInfoModalOpen(!isInfoModalOpen);
  };

  return {
    isInfoModalOpen,
    toggleInfoModal,
  };
}

export function useStatisticsModal() {
  const [isStatisticsModalOpen, setIsStatisticsModalOpen] = useState(false);

  const toggleStatisticsModal = () => {
    setIsStatisticsModalOpen(!isStatisticsModalOpen);
  };

  return {
    isStatisticsModalOpen,
    toggleStatisticsModal
  };
}

export function useSettingsModal() {
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);

  const toggleSettingsModal = () => {
    setIsSettingsModalOpen(!isSettingsModalOpen);
  };

  return {
    isSettingsModalOpen,
    toggleSettingsModal
  };
}

export function usePastGamesModal() {
  const [isPastGamesModalOpen, setIsPastGamesModalOpen] = useState(false);

  const togglePastGamesModal = () => {
    setIsPastGamesModalOpen(!isPastGamesModalOpen);
  };

  return {
    isPastGamesModalOpen,
    togglePastGamesModal
  };
}