import React, { ReactNode } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import "./modal.css";

interface ModalProps {
  content: ReactNode;
  isOpen: boolean;
  toggle: () => void;
}

export default function Modal(props: ModalProps) {
  return (
    <>
      {props.isOpen && (
        <div className="modal-overlay" onClick={props.toggle}>
          <div onClick={(e) => e.stopPropagation()} className="modal-container">
            <div className="modal-header">
              <div className="modal-close-button" onClick={props.toggle}>
                <FontAwesomeIcon icon={faXmark} />
              </div>
            </div>
            <div className="modal-content">
              {props.content}
            </div>
          </div>
        </div>
      )}
    </>
  );
}