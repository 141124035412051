import React from "react";
import "./modal.css";
import Modal from "../modals/modal";
import useDateIndex from "../../hooks/useDateIndex";
import { faShareAlt, faCopy } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface StatisticsModalProps {
  isOpen: boolean;
  toggle: () => void;
  isGameOver: boolean;
  solveTime: string;
}

export default function StatisticsModal(props: StatisticsModalProps) {
  const { getDayIndex } = useDateIndex();

  function copyCode(textToShare: string) {
    navigator.clipboard.writeText(textToShare)
    //TODO: open `copied` toast
  }

  const handleSharing = async (textToShare: string) => {
    if (navigator.share) {
      try {
        await navigator
          .share({
            title: 'Pyramini Daily Score',
            text: textToShare
          });
      } catch (error) {
        console.log(`Oops! I couldn't share to the world because: ${error}`);
      }
    } else {
      // fallback code
      copyCode(textToShare);
    }
  };
  
  const textToShare = `I solved pyramini.com #${getDayIndex()} in ${props.solveTime}`; 

  const gameOverContent = (
    <div className="game-over-content">
      <h3>Congratulations, you solved it!</h3>
      <p>Your time: {props.solveTime}</p>
      <span>Share your success</span>
      {navigator['share'] ? 
        <div className="column">
          <button className="share-button button" 
                  onClick={() => {
                    handleSharing(textToShare);
                  }}>
            Share <FontAwesomeIcon icon={faShareAlt} />
          </button>
          <button className="copy-button button" 
                onClick={() => {
                  copyCode(textToShare);
                }}>
            Copy <FontAwesomeIcon icon={faCopy} />
          </button>
        </div> :
        <button className="copy-button button" 
                onClick={() => {
                  copyCode(textToShare);
                }}>
          Copy <FontAwesomeIcon icon={faCopy} />
        </button>
      }
    </div>
  );

  const gameNotOverContent = (
    <div className="game-not-over-content">
      <h3>You haven't solved the puzzle yet!</h3>
      <p>Come back once you've solved it.</p>
    </div>
  );

  // const statisticsContent = (
  //   <div className="statistics-content">
  //     <h3>Statistics</h3>
  //     <p>Total solves: TODO</p>
  //     <p>Average solve time: TODO</p>
  //   </div>
  // );

  const content = (
    <div className="statistics-modal-container">
      {props.isGameOver ? gameOverContent : gameNotOverContent}
      {/* {statisticsContent} */}
    </div>
  )

  return (
    <Modal content={content} isOpen={props.isOpen} toggle={props.toggle} />
  );
}