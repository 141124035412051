import React from "react";
import "./modal.css";
import Modal from "./modal";
import { Calendar } from "../calendar/calendar";

interface PastGamesModalProps {
  isOpen: boolean;
  toggle: () => void;
  changeWordPyramid: (dayIndex: number) => void;
  currentPyramidDateIndex: number,
}

export default function PastGamesModal(props: PastGamesModalProps) {
  const content = (
    <div className="past-games-modal-content">
      <h3>Past Games</h3>
      <Calendar changeWordPyramid={props.changeWordPyramid} toggleModal={props.toggle} currentPyramidDateIndex={props.currentPyramidDateIndex} />
    </div>
  )

  return (
    <Modal content={content} isOpen={props.isOpen} toggle={props.toggle} />
  );
}