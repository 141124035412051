import React from "react";
import "./modal.css";
import Modal from "../modals/modal";

interface InfoModalProps {
  isOpen: boolean;
  toggle: () => void;
}

export default function InfoModal(props: InfoModalProps) {
  const content = (
    <div className="info-modal-content">
      <h3>Information</h3>
      <p>💡 Welcome to Pyramini, the crossword anagram game 🧠</p>
      <p>Fill in the pyra-grid with answers to the clues.</p>
      <p>Each row contains the same letters as the one above it, with one extra letter added in!</p>
      <p>The letters can appear in any order.</p>
      <p>🛕 Good luck! 🐪</p>
    </div>
  )

  return (
    <Modal content={content} isOpen={props.isOpen} toggle={props.toggle} />
  );
}