import React, { useState } from 'react';
import './App.css';
import { Game } from './app_components/game/game';
import { WORD_PYRAMID_SOLUTION_TEST_EASY, WORD_PYRAMID_SOLUTION_TEST_MEDIUM, WORD_PYRAMID_SOLUTION_TEST_HARD } from './app_components/utils/word_pyramid_classes';
import useDateIndex from './hooks/useDateIndex';
import { dailyPyramids } from './app_components/utils/word_pyramid_solutions';

const App = () => {
  const { getDayIndex } = useDateIndex();
  const [puzzleIndex, setPuzzleIndex] = useState(getDayIndex() % dailyPyramids.length)
  const [wordPyramid, setWordPyramid] = useState(dailyPyramids[puzzleIndex]);

  var urlParts = window.location.href.split("#");
  if (urlParts.length > 0) {
    const puzzleId = urlParts[1];

    if (puzzleId === "hard") {
      setWordPyramid(WORD_PYRAMID_SOLUTION_TEST_HARD);
    } else if (puzzleId === "medi") {
      setWordPyramid(WORD_PYRAMID_SOLUTION_TEST_MEDIUM);
    } else if (puzzleId === "easy") {
      setWordPyramid(WORD_PYRAMID_SOLUTION_TEST_EASY);
    } else {
      const puzzleIdAsNumber = Number(puzzleId);
      if (!isNaN(puzzleIdAsNumber)) {
        if (puzzleIndex !== puzzleIdAsNumber) {
          changeWordPyramid(puzzleIdAsNumber);
        }
      }
    }
  }

  function changeWordPyramid(dayIndex: number) {
    if (dayIndex >= dailyPyramids.length) {
      console.log("ERROR: cannot change to a word pyramid that doesn't exist");
      return;
    }

    window.location.href = `${urlParts[0]}#${dayIndex}`
    setPuzzleIndex(dayIndex);
    setWordPyramid(dailyPyramids[dayIndex]);
  }

  return (
    <div className="App">
      <header className="App-header">
        <Game solution={wordPyramid} currentPyramidDateIndex={puzzleIndex} changeWordPyramid={changeWordPyramid} />
        {/* <Game solution={WORD_PYRAMID_SOLUTION_TEST_MEDIUM}/> */}
      </header>
    </div>
  );
}

export default App;
