import React from 'react';
import "./word_pyramid.css";
import {WordPyramidAttempt, WordPyramidRowDataObject} from '../utils/word_pyramid_classes'

// Reference: https://levelup.gitconnected.com/typescript-and-react-using-create-react-app-a-step-by-step-guide-to-setting-up-your-first-app-6deda70843a4
interface WordPyramidProps {
  currentPyramid: WordPyramidAttempt;
  selectedRow: number;
  selectedIndex: number;
  onClick: Function;
}

export const WordPyramid: React.FC<WordPyramidProps> = (props: WordPyramidProps) => (
  <div className="word-pyramid-container">
    {props.currentPyramid.getAllRows().map(function(row: WordPyramidRowDataObject, key: number) {
      return <WordPyramidRow key={key} rowKey={key} isSelectedRow={props.selectedRow === key} selectedIndex={props.selectedRow === key ? props.selectedIndex : -1} currentWord={row.getValues()} onClick={(index: number) => props.onClick(key, index)}/>
    })}
  </div>
);

WordPyramid.defaultProps = {
  selectedIndex: -1,
};

interface WordPyramidRowProps {
  rowKey: number;
  currentWord: string[];
  isSelectedRow: boolean;
  selectedIndex: number;
  onClick: Function;
}

const WordPyramidRow: React.FC<WordPyramidRowProps> = (props: WordPyramidRowProps) => (
  <div className="word-pyramid-row-container">
    {props.currentWord.map(function(letter: string, key: number) {
      return <LetterSquare key={key} letterKey={key} rowKey={props.rowKey} currentLetter={letter} cornerNumber={key === 0 ? props.currentWord.length - 2 : undefined} isSelected={key === props.selectedIndex} isInSelectedRow={props.isSelectedRow} onClick={() => props.onClick(key)} />
    })}
  </div>
);

WordPyramidRow.defaultProps = {
  selectedIndex: -1,
};

interface LetterSquareProps {
  letterKey: number,
  rowKey: number,
  currentLetter: string;
  cornerNumber?: number
  isSelected: boolean;
  isInSelectedRow: boolean;
  onClick: React.MouseEventHandler<HTMLDivElement>;
}

const LetterSquare: React.FC<LetterSquareProps> = (props: LetterSquareProps) => (
  <div className={"letter-square-container" + (props.isSelected ? " selected" : (props.isInSelectedRow ? " highlighted" : ""))} onClick={props.onClick}>
    <div className="corner-number">{props.cornerNumber}</div>
    <div id={'square-' + props.rowKey + '-' + props.letterKey} className='current-letter'>
      {props.currentLetter}
    </div>
  </div>
);