import React from 'react';
import "./title_bar.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo, faChartSimple, faLifeRing, faChevronLeft, faGear, faCalendar } from '@fortawesome/free-solid-svg-icons'
import { useRef, useState } from "react";
import { SelectionType } from '../utils/utils';

// Reference: https://levelup.gitconnected.com/typescript-and-react-using-create-react-app-a-step-by-step-guide-to-setting-up-your-first-app-6deda70843a4
interface TitleBarProps {
  isGameOver: boolean,
  solveTime: string,
  toggleInfoModal: () => void,
  toggleStatisticsModal: () => void,
  toggleSettingsModal: () => void,
  togglePastGamesModal: () => void,
  checkSelection: any,
  revealSelection: any,
  clearSelection: any,
}

enum DropdownOption {
  HELP,
  CHECK,
  REVEAL,
  CLEAR
}

export const TitleBar: React.FC<TitleBarProps> = (props: TitleBarProps) => {
  const [helpDropdownOpen, setHelpDropdownOpen] = useState<boolean>(false);
  const [checkDropdownOpen, setCheckDropdownOpen] = useState<boolean>(false);
  const [revealDropdownOpen, setRevealDropdownOpen] = useState<boolean>(false);
  const [clearDropdownOpen, setClearDropdownOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null)

  function resetDropdownMenus() {
    setCheckDropdownOpen(false);
    setRevealDropdownOpen(false);
    setClearDropdownOpen(false);

    document.getElementById("check-dropdown-button")?.classList.remove("selected");
    document.getElementById("reveal-dropdown-button")?.classList.remove("selected");
    document.getElementById("clear-dropdown-button")?.classList.remove("selected");
  }

  const handleDropDownFocus = (dropdownOption: DropdownOption, state: boolean) => {
    switch (dropdownOption) {
      case DropdownOption.HELP:
        setHelpDropdownOpen(!state);
        resetDropdownMenus();
        break;
      case DropdownOption.CHECK:
        resetDropdownMenus();

        setCheckDropdownOpen(!state);
        document.getElementById("check-dropdown-button")?.classList.add("selected");
        break;
      case DropdownOption.REVEAL:
        resetDropdownMenus();

        setRevealDropdownOpen(!state);
        document.getElementById("reveal-dropdown-button")?.classList.add("selected");
        break;
      case DropdownOption.CLEAR:
        resetDropdownMenus();

        setClearDropdownOpen(!state);
        document.getElementById("clear-dropdown-button")?.classList.add("selected");
        break;
      default:
        console.log(`Error, invalid dropdown option:${dropdownOption}`)
        break;
    }

  };
  const handleClickOutsideDropdown = (e: any) => {
    if (helpDropdownOpen && !dropdownRef.current?.contains(e.target as Node)) {
      setHelpDropdownOpen(false);
      resetDropdownMenus();
    }
  }
  window.addEventListener("click", handleClickOutsideDropdown)

  return (
    <div className="title-bar-container">
      <ul className="title-bar-left-side">
        <li>
          <div className="title-bar-button" onClick={props.toggleSettingsModal}>
            <FontAwesomeIcon icon={faGear} />
          </div>
        </li>
        <li>
          <div className="title-bar-button" onClick={props.togglePastGamesModal}>
            <FontAwesomeIcon icon={faCalendar} />
          </div>
        </li>
        <li>
          <div className="title-bar-button" onClick={props.toggleStatisticsModal}>
            {props.solveTime}
          </div>
        </li>
      </ul>

      <div className="title-bar-middle">
        <a className='title' href="https://www.pyramini.com">PYRAMINI</a>
      </div>

      <ul className="title-bar-right-side">
        <li>
          <div className="help-dropdown-container" ref={dropdownRef}>
            <div className="title-bar-button">
              <FontAwesomeIcon icon={faLifeRing} onClick={(e) => handleDropDownFocus(DropdownOption.HELP, helpDropdownOpen)} />
            </div>
            {helpDropdownOpen && (
              <ul className='help-dropdown'>

                {checkDropdownOpen && (
                  <ul className='check-dropdown'>
                    <li onClick={() => { props.checkSelection(SelectionType.LETTER) }}>
                      Letter
                    </li>
                    <li onClick={() => { props.checkSelection(SelectionType.WORD) }}>
                      Word
                    </li>
                    <li onClick={() => { props.checkSelection(SelectionType.PUZZLE) }}>
                      Puzzle
                    </li>
                  </ul>
                )}

                {revealDropdownOpen && (
                  <ul className='reveal-dropdown'>
                    <li onClick={() => { props.revealSelection(SelectionType.LETTER) }}>
                      Letter
                    </li>
                    <li onClick={() => { props.revealSelection(SelectionType.WORD) }}>
                      Word
                    </li>
                    <li onClick={() => { props.revealSelection(SelectionType.PUZZLE) }}>
                      Puzzle
                    </li>
                  </ul>
                )}

                {clearDropdownOpen && (
                  <ul className='clear-dropdown'>
                    <li onClick={() => { props.clearSelection(SelectionType.LETTER) }}>
                      Letter
                    </li>
                    <li onClick={() => { props.clearSelection(SelectionType.WORD) }}>
                      Word
                    </li>
                    <li onClick={() => { props.clearSelection(SelectionType.PUZZLE) }}>
                      Puzzle
                    </li>
                  </ul>
                )}

                <li id="check-dropdown-button" onClick={(e) => handleDropDownFocus(DropdownOption.CHECK, checkDropdownOpen)}>
                  <FontAwesomeIcon className="left-arrow" icon={faChevronLeft} size='2xs' />
                  Check
                </li>
                <li id="reveal-dropdown-button" onClick={(e) => handleDropDownFocus(DropdownOption.REVEAL, revealDropdownOpen)}>
                  <FontAwesomeIcon className="left-arrow" icon={faChevronLeft} size='2xs' />
                  Reveal
                </li>
                <li id="clear-dropdown-button" onClick={(e) => handleDropDownFocus(DropdownOption.CLEAR, clearDropdownOpen)}>
                  <FontAwesomeIcon className="left-arrow" icon={faChevronLeft} size='2xs' />
                  Clear
                </li>
              </ul>
            )}
          </div>
        </li>
        <li>
          <div className="title-bar-button" onClick={props.toggleStatisticsModal}>
            <FontAwesomeIcon icon={faChartSimple} />
          </div>
        </li>
        <li>
          <div className="title-bar-button" onClick={props.toggleInfoModal}>
            <FontAwesomeIcon icon={faCircleInfo} />
          </div>
        </li>
      </ul>
    </div>
  );
};