export default function useDateIndex() {
  const inceptionDate = new Date("12/20/2022");

  function getDayIndex() {
    var today = new Date();
  
    var difference_in_time = today.getTime() - inceptionDate.getTime();
      
    // To calculate the number of days between two dates
    return Math.floor(difference_in_time / (1000 * 3600 * 24));
  }

  return {
    getDayIndex
  };
}