import React, { useEffect, useState } from 'react';
import { WordPyramid } from '../word_pyramid/word_pyramid'
import { WordPyramidSolution, WordPyramidAttempt, MIN_WORD_LENGTH } from '../utils/word_pyramid_classes'
import { isDirection, isAlphabetic, EMPTY_LETTER, findFirstEmptyIndex, DELETE, SelectionType, Cookie, PAST_SOLUTION_CONTENT_DELIMETER, PAST_SOLUTION_DELIMETER } from '../utils/utils'
import { KeyboardContainer } from '../keyboard/keyboard';
import { TitleBar } from '../title_bar/title_bar';
import "./game.css";
import useSolveTime from '../../hooks/useSolveTime';
import InfoModal from "../modals/info_modal";
import StatisticsModal from '../modals/statistics_modal';
import SettingsModal from '../modals/settings_modal';
import PastGamesModal from '../modals/past_games_modal';
import { useInfoModal, useStatisticsModal, useSettingsModal, usePastGamesModal } from '../../hooks/useModal';

interface GameProps {
  solution: WordPyramidSolution,
  currentPyramidDateIndex: number,
  changeWordPyramid: (dayIndex: number) => void,
}

export function Game(props: GameProps) {
  const [currentPyramid, setCurrentPyramid] = useState(new WordPyramidAttempt(props.solution));
  const [selectedRow, setSelectedRow] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isGameOver, setIsGameOver] = useState(false);
  const [solveStartTime, setSolveStartTime] = useState("");
  const [solveFinishedTime, setSolveFinishedTime] = useState("");

  // resets the game every day
  useEffect(() => {
    const todaysHash = props.solution.getHash();

    if (window.localStorage.getItem(Cookie.PAST_SOLUTIONS)?.includes(todaysHash)) {
      // Pyramini has already been solved
      setCurrentPyramid(currentPyramid => currentPyramid.solve(props.solution));
      setIsGameOver(true);
      setSolveFinishedTime(getSolutionTimeOfPastSolve(todaysHash));
    } else {
      if (window.localStorage.getItem(Cookie.HASH) !== todaysHash) {
        window.localStorage.setItem(Cookie.HASH, todaysHash)

        window.localStorage.removeItem(Cookie.CURRENT_PYRAMID);
        window.localStorage.removeItem(Cookie.SOLVE_TIME);

        window.localStorage.setItem(Cookie.IS_GAME_OVER, "false");
        window.localStorage.setItem(Cookie.START_TIME, getCurrentTime());
      }

      setSolveFinishedTime(window.localStorage.getItem(Cookie.SOLVE_TIME) ?? "");
      setIsGameOver(window.localStorage.getItem(Cookie.IS_GAME_OVER) === "true");
      setSolveStartTime(window.localStorage.getItem(Cookie.START_TIME)!)
      setCurrentPyramid(new WordPyramidAttempt(props.solution, window.localStorage.getItem(Cookie.CURRENT_PYRAMID) ?? undefined))
    }
  }, [props.solution]);

  function getCurrentTime(): string {
    return (Date.now() / 1000).toString();
  }

  const { isInfoModalOpen, toggleInfoModal } = useInfoModal();
  const { isStatisticsModalOpen, toggleStatisticsModal } = useStatisticsModal();
  const { isSettingsModalOpen, toggleSettingsModal } = useSettingsModal();
  const { isPastGamesModalOpen, togglePastGamesModal } = usePastGamesModal();
  const { getTotalSolveTime } = useSolveTime({ getCurrentTime });

  function getRowLength(row: number): number {
    return row + MIN_WORD_LENGTH;
  }

  function checkCorrect() {
    if (isCorrect()) {
      setIsGameOver(true);
      window.localStorage.setItem(Cookie.IS_GAME_OVER, "true");

      if (solveFinishedTime === "") {
        // Was having some asynchronisity issues so I made this a variable that I can pass to both "setting" functions
        const newSolveTime = getTotalSolveTime(solveStartTime);
        setSolveFinishedTime(newSolveTime);
        window.localStorage.setItem(Cookie.SOLVE_TIME, newSolveTime);

        updatePastSolutions(newSolveTime);
      }
      toggleStatisticsModal();
    }
    // TODO: make pop up for not correct, and only trigger this method when grid is full
  }

  function updatePastSolutions(newSolveTime: string) {
    const pastSolutions = window.localStorage.getItem(Cookie.PAST_SOLUTIONS)?.split("&");
    if (pastSolutions) {
      const solutionIsNew = pastSolutions.filter((pastSolution) => {
        const hash = pastSolution.split(PAST_SOLUTION_CONTENT_DELIMETER)[0];
        return hash === window.localStorage.getItem(Cookie.HASH);
      }).length === 0;

      if (solutionIsNew) {
        window.localStorage.setItem(Cookie.PAST_SOLUTIONS, getUpdatedPastSolutionsWithNewSolution(newSolveTime));
      }

    } else {
      window.localStorage.setItem(Cookie.PAST_SOLUTIONS, createPastSolution(window.localStorage.getItem(Cookie.HASH)!, newSolveTime));
    }
  }

  function getUpdatedPastSolutionsWithNewSolution(solveTime: string): string {
    const newSolution = createPastSolution(window.localStorage.getItem(Cookie.HASH)!, solveTime);
    return `${window.localStorage.getItem(Cookie.PAST_SOLUTIONS)}${PAST_SOLUTION_DELIMETER}${newSolution}`;
  }

  function getSolutionTimeOfPastSolve(puzzleHash: string): string {
    const pastSolutions = window.localStorage.getItem(Cookie.PAST_SOLUTIONS)?.split("&");
    if (!pastSolutions) {
      console.log("Cannot get past solution time without past solutions");
      return '';
    }

    const pastSolve = pastSolutions.filter((solution) => solution.includes(puzzleHash));
    if (pastSolve.length === 0) {
      console.log("Cannot get past solution time of non-existant solution");
      return '';
    }

    // example solve: 22172831b09c83c7bbf3b187930050692743b9ad=0:10
    const solveParts = pastSolve[0].split('=');
    return solveParts[1];
  }

  function createPastSolution(hash: string, solveTime: string) {
    return `${hash}${PAST_SOLUTION_CONTENT_DELIMETER}${solveTime}`;
  }

  function isCorrect(): boolean {
    var value: boolean = true;
    currentPyramid.getAllRows().forEach((row, index) => {
      if (row.getValues().join("") !== props.solution.getWord(index)) {
        value = false;
      }
    });
    return value;
  }

  function decrementIndex(row: number, index: number) {
    const preceedingRowAndIndex = getPreceedingRowAndIndex(row, index);
    setSelectedRow(preceedingRowAndIndex[0]);
    setSelectedIndex(preceedingRowAndIndex[1]);
  }

  // Returns the preceeding row and index as an array in the form [row, index]
  function getPreceedingRowAndIndex(currentRow: number, currentIndex: number): number[] {
    if (currentIndex > 0) {
      return [currentRow, currentIndex - 1];
    } else if (currentRow > 0) {
      return [currentRow - 1, getRowLength(currentRow - 1) - 1];
    } else {
      return [currentRow, currentIndex];
    }
  }

  function incrementIndex(row: number, index: number) {
    const followingRowAndIndex = getFollowingRowAndIndex(row, index);
    setSelectedRow(followingRowAndIndex[0]);
    setSelectedIndex(followingRowAndIndex[1]);

    // Check to see if the puzzle has been solved
    checkCorrect();
  }

  function getFollowingRowAndIndex(currentRow: number, currentIndex: number): number[] {
    if (currentIndex < getRowLength(currentRow) - 1) {
      return [currentRow, currentIndex + 1];
    } else if (currentRow < props.solution.getSize() - MIN_WORD_LENGTH) {
      return [currentRow + 1, 0];
    } else {
      return [currentRow, currentIndex];
    }
  }

  function updateCorrectnessOfRow(rowIndex: number) {
    const solutionWord = props.solution.getWord(rowIndex);
    for (var letterIndex = 0; letterIndex < solutionWord.length; letterIndex++) {
      updateCorrectnessOfLetter(rowIndex, letterIndex);
    }
  }

  function updateCorrectnessOfLetter(rowIndex: number, letterIndex: number) {
    const currentLetter = currentPyramid.getRow(rowIndex).getLetter(letterIndex);
    const solutionLetter = props.solution.getWord(rowIndex).at(letterIndex);
    if (currentLetter === solutionLetter) {
      document.getElementById('square-' + rowIndex + '-' + letterIndex)?.classList.add('correct-letter');
    } else {
      document.getElementById('square-' + rowIndex + '-' + letterIndex)?.classList.add('incorrect-letter');
    }
  }

  function checkSelection(selectionType: SelectionType) {
    switch (selectionType) {
      case SelectionType.LETTER:
        updateCorrectnessOfLetter(selectedRow, selectedIndex);
        break;
      case SelectionType.WORD:
        updateCorrectnessOfRow(selectedRow);
        break;
      case SelectionType.PUZZLE:
        for (var rowIndex = 0; rowIndex < props.solution.getSize() - MIN_WORD_LENGTH + 1; rowIndex++) {
          updateCorrectnessOfRow(rowIndex);
        }
        break;
      default:
        console.log(`error: invalid checkSelection selection type: ${selectionType}`)
        break;
    }
  }

  function revealRow(rowIndex: number) {
    const solutionWord = props.solution.getWord(rowIndex);
    for (var letterIndex = 0; letterIndex < solutionWord.length; letterIndex++) {
      revealLetter(rowIndex, letterIndex);
    }
  }

  function revealLetter(rowIndex: number, letterIndex: number) {
    const solutionLetter = props.solution.getWord(rowIndex).at(letterIndex);
    updateLetter(rowIndex, letterIndex, solutionLetter!);
    document.getElementById('square-' + rowIndex + '-' + letterIndex)?.classList.add('correct-letter');
  }

  function revealSelection(selectionType: SelectionType) {
    switch (selectionType) {
      case SelectionType.LETTER:
        revealLetter(selectedRow, selectedIndex);
        break;
      case SelectionType.WORD:
        revealRow(selectedRow);
        break;
      case SelectionType.PUZZLE:
        for (var rowIndex = 0; rowIndex < props.solution.getSize() - MIN_WORD_LENGTH + 1; rowIndex++) {
          revealRow(rowIndex);
        }
        break;
      default:
        console.log(`error: invalid revealSelection selection type: ${selectionType}`)
        break;
    }
  }

  function clearRow(rowIndex: number) {
    const solutionWord = props.solution.getWord(rowIndex);
    for (var letterIndex = 0; letterIndex < solutionWord.length; letterIndex++) {
      clearLetter(rowIndex, letterIndex);
    }
  }

  function clearLetter(rowIndex: number, letterIndex: number) {
    // If a letter is cleared, allow the user to begin playing again if the game was previously over.
    if (isGameOver) {
      setIsGameOver(false);
      window.localStorage.setItem(Cookie.IS_GAME_OVER, "false");
    }

    const currentLetter = currentPyramid.getRow(rowIndex).getLetter(letterIndex);
    if (currentLetter !== EMPTY_LETTER) {
      updateLetter(rowIndex, letterIndex, EMPTY_LETTER);
    }
  }

  function clearSelection(selectionType: SelectionType) {
    switch (selectionType) {
      case SelectionType.LETTER:
        clearLetter(selectedRow, selectedIndex);
        break;
      case SelectionType.WORD:
        clearRow(selectedRow);
        break;
      case SelectionType.PUZZLE:
        for (var rowIndex = 0; rowIndex < props.solution.getSize() - MIN_WORD_LENGTH + 1; rowIndex++) {
          clearRow(rowIndex);
        }
        break;
      default:
        console.log(`error: invalid clearSelection selection type: ${selectionType}`)
        break;
    }
  }

  function updateLetter(row: number, column: number, letter: string) {
    if (letter === EMPTY_LETTER) {
      if (currentPyramid.getLetter(row, column) === EMPTY_LETTER) {
        // *** I had some problems with the asynchronisity of the decrementIndex function,
        // which is why I call that function last ***

        // If the current index is empty, remove the previous letter and decrement the index
        const preceedingRowAndIndex = getPreceedingRowAndIndex(row, column);
        currentPyramid.setLetter(preceedingRowAndIndex[0], preceedingRowAndIndex[1], letter);
        decrementIndex(selectedRow, selectedIndex);
      } else {
        // Else, set the current index's value to EMPTY_LETTER and DO NOT decrement index
        currentPyramid.setLetter(row, column, letter);
      }
    } else {
      currentPyramid.setLetter(row, column, letter);

      // Remove any previous coloring from the letter
      document.getElementById('square-' + row + '-' + column)?.classList.remove('correct-letter');
      document.getElementById('square-' + row + '-' + column)?.classList.remove('incorrect-letter');

      // A normal letter was clicked, so move selected index forward one
      incrementIndex(selectedRow, selectedIndex);
    }

    // Save the new pyramid to local storage
    window.localStorage.setItem(Cookie.CURRENT_PYRAMID, currentPyramid.toString());

    // // Ensures that any asynchronous letter or index updates are shown on the screen.
    setCurrentPyramid(new WordPyramidAttempt(props.solution, currentPyramid.toString()));
  }

  function handleClickOnLetterSquare(row: number, index: number) {
    setSelectedRow(row);
    setSelectedIndex(index);
  }

  function handleLeftArrowClick() {
    // First move to the previous row
    const newRow = selectedRow > 0 ? selectedRow - 1 : selectedRow;
    // Then set the index to the first empty index, or the end if none are empty
    const newIndex = findFirstEmptyIndex(currentPyramid.getRow(newRow));

    setSelectedRow(newRow);
    setSelectedIndex(newIndex);
  }

  function handleRightArrowClick() {
    // First move to the next row
    const newRow = selectedRow < props.solution.getSize() - MIN_WORD_LENGTH ? selectedRow + 1 : selectedRow;
    // Then set the index to the first empty index, or the end if none are empty
    const newIndex = findFirstEmptyIndex(currentPyramid.getRow(newRow));

    setSelectedRow(newRow);
    setSelectedIndex(newIndex);
  }

  function handleKeyboardLetterClick(letter: string) {
    if (isGameOver) {
      return; // Don't let the user change the board once its solved.
    }

    if (isAlphabetic(letter)) {
      updateLetter(selectedRow, selectedIndex, letter.toUpperCase());
    } else if (letter === DELETE) {
      updateLetter(selectedRow, selectedIndex, EMPTY_LETTER);
    }
  }

  function eventKeyHandler(event: React.KeyboardEvent<HTMLDivElement>): void {
    if (isDirection(event.code)) {
      if (event.code === "ArrowLeft") {
        decrementIndex(selectedRow, selectedIndex);
      } else if (event.code === "ArrowRight") {
        incrementIndex(selectedRow, selectedIndex);
      } else if (event.code === "ArrowDown") {
        handleRightArrowClick();
      } else if (event.code === "ArrowUp") {
        handleLeftArrowClick();
      } else {
        throw Error("key event " + event.code + " has not been implemented");
      }
    } else if (event.code === "Backspace") {
      if (isGameOver) {
        return; // Don't let the user change the board once its solved.
      }

      updateLetter(selectedRow, selectedIndex, EMPTY_LETTER);
    } else if (event.code.startsWith('Key')) {
      if (isGameOver) {
        return; // Don't let the user change the board once its solved.
      }

      const letter = event.code.substring(3);
      if (isAlphabetic(letter)) {
        updateLetter(selectedRow, selectedIndex, letter.toUpperCase());
      }
    }
    // Keeping this commented code here for easy testing later
    // else {
    //   console.log(event.code);
    // }
  }

  return (
    <div className="game-container" tabIndex={0} onKeyDown={eventKeyHandler} >
      <TitleBar isGameOver={false} solveTime={solveFinishedTime !== "" ? solveFinishedTime : getTotalSolveTime(solveStartTime)} toggleInfoModal={toggleInfoModal} toggleStatisticsModal={toggleStatisticsModal} toggleSettingsModal={toggleSettingsModal} togglePastGamesModal={togglePastGamesModal} checkSelection={checkSelection} revealSelection={revealSelection} clearSelection={clearSelection} />
      <WordPyramid currentPyramid={currentPyramid} selectedRow={selectedRow} selectedIndex={selectedIndex} onClick={(row: number, index: number) => handleClickOnLetterSquare(row, index)} />
      <KeyboardContainer currentClue={props.solution.getClue(selectedRow)} onLeftArrowClick={handleLeftArrowClick} onRightArrowClick={handleRightArrowClick} onKeyboardLetterClick={handleKeyboardLetterClick} />

      <InfoModal isOpen={isInfoModalOpen} toggle={toggleInfoModal} />
      <StatisticsModal isOpen={isStatisticsModalOpen} toggle={toggleStatisticsModal} isGameOver={isGameOver} solveTime={solveFinishedTime !== "" ? solveFinishedTime : getTotalSolveTime(solveStartTime)} />
      <SettingsModal isOpen={isSettingsModalOpen} toggle={toggleSettingsModal} />
      <PastGamesModal isOpen={isPastGamesModalOpen} toggle={togglePastGamesModal} changeWordPyramid={props.changeWordPyramid} currentPyramidDateIndex={props.currentPyramidDateIndex} />
    </div>
  );
}

/**
 * Cookies Design
 *
 * currentPyramid: ___/_____/_____/______/_______/________
 * todaysHash: bf4d3f8b447c2a92c141a8c408b1f345711b6da0
 * pastSolutions:
 *   {hash}:{solveTime}&{hash}:{solveTime}&{hash}:{solveTime}...
 */