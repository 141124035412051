import { EMPTY_LETTER } from './utils'

export const MIN_WORD_LENGTH = 3;
var hash = require('object-hash');

export class WordPyramidSolution {
  // N x 2 double array where each row contains two values, the word and the clue to the word
  // The top row is the smallest word (3 letters), and the Nth row is the longest word (N + 2 letters)
  wordPyramid: string[][];

  constructor(wordPyramid: string[][]) {
    this.wordPyramid = wordPyramid;
  }

  getSize(): number {
    return this.wordPyramid[this.wordPyramid.length - 1][0].length;
  }

  getWord(index: number): string {
    return this.wordPyramid[index][0].toUpperCase();
  }

  getClue(index: number): string {
    return this.wordPyramid[index][1];
  }

  getClues(): string[] {
    return this.wordPyramid.map(function (row) {
      return row[1];
    })
  }

  getHash(): string {
    return hash(this.wordPyramid);
  }
}

export const WORD_PYRAMID_SOLUTION_TEST = new WordPyramidSolution([["ABC", "Three letters"],
["ABCD", "Four letters"],
["ABCDE", "Hi Kayla are you seeing this?"],
["ABCDEF", "If so respond below:"],
["ABCDEFG", "seven letters"],
["ABCDEFGH", "eight letters"]]);

export const WORD_PYRAMID_SOLUTION_TEST_HARD = new WordPyramidSolution([["ate", "Had a starter, say"],
['rate', 'Review on Yelp, say'],
['alert', '"Heads up!" e.g.'],
['retail', 'What one doesn\'t pay at a clearance sale'],
['article', 'Newspaper feature'],
['literacy', 'An aim of education']]);

export const WORD_PYRAMID_SOLUTION_TEST_MEDIUM = new WordPyramidSolution([["the", "Kermit's middle name?"],
['tech', 'Georgia or Cal.'],
['chest', 'Treasure container'],
['cheats', 'Has an ace up one\'s sleeve, say'],
['teaches', 'Fills kids\' heads with all sorts of stuff'],
['cheapest', 'Least likely to tip']]);

export const WORD_PYRAMID_SOLUTION_TEST_EASY = new WordPyramidSolution([
  ["gin", "Juniper liquor"],
  ["nigh", "Approaching, in romantic poetry"],
  ["singh", "Indian prime minister before Modi"],
  ["hosing", "Watering by tube"],
  ["shooing", "Driving away"],
  ["shooting", "Like some pains"]]);

export class WordPyramidAttempt {
  DELIMETER = "/";

  size: number;
  all_attempts: WordPyramidRowDataObject[];

  // currentPyramid is passed in via cookies, which is why it needs to be a string.
  constructor(wordPyramid: WordPyramidSolution, currentPyramidAsJson?: string) {
    this.size = wordPyramid.getSize();
    this.all_attempts = Array.from(Array(this.size + 1).keys()).filter(function (attempt_length) {
      return attempt_length >= MIN_WORD_LENGTH;
    }).map(function (attempt_length) {
      return new WordPyramidRowDataObject(attempt_length);
    });

    if (currentPyramidAsJson) {
      this.setAttemptContents(currentPyramidAsJson);
    }
  }

  getRow(rowNumber: number): WordPyramidRowDataObject {
    return this.all_attempts[rowNumber];
  }

  getAllRows(): WordPyramidRowDataObject[] {
    return this.all_attempts;
  }

  getLetter(row: number, index: number): string {
    return this.all_attempts[row].getValues()[index];
  }

  setLetter(row: number, index: number, letter: string): void {
    this.all_attempts[row].setValue(index, letter);
  }

  solve(solution: WordPyramidSolution) {
    let solutionAsString = solution.getWord(0);
    for (let i = 1; i <= solution.getSize() - MIN_WORD_LENGTH; i++) {
      solutionAsString += "/" + solution.getWord(i);
    }
    this.setAttemptContents(solutionAsString);
    return this;
  }

  // Expects a string of form ___/____/_____/______/_______/________ where each underscore is either an empty space or a letter.
  private setAttemptContents(wordPyramidAttemptAsString: string) {
    const wordPyramidRows: string[] = wordPyramidAttemptAsString.split("/");
    wordPyramidRows.forEach((row, rowIndex) => {
      for (var letterIndex = 0; letterIndex < row.length; letterIndex++) {
        this.all_attempts[rowIndex].setValue(letterIndex, row[letterIndex]);
      }
    });
  }

  toString() {
    var wordPyramidAsString = '';
    this.all_attempts.forEach(row => {
      row.getValues().forEach(letter => {
        wordPyramidAsString += letter;
      })
      wordPyramidAsString += this.DELIMETER;
    });
    return wordPyramidAsString;
  }
}

export class WordPyramidRowDataObject {
  attempt: string[];

  constructor(size: number) {
    this.attempt = Array(size).fill(EMPTY_LETTER);
  }

  getValues(): string[] {
    return this.attempt;
  }

  setValue(index: number, letter: string) {
    this.attempt[index] = letter;
  }

  getLetter(index: number) {
    return this.attempt[index];
  }
}