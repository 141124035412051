import React from 'react';
import "./keyboard.css";

import { DELETE } from '../utils/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faDeleteLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'

// Reference: https://levelup.gitconnected.com/typescript-and-react-using-create-react-app-a-step-by-step-guide-to-setting-up-your-first-app-6deda70843a4
interface KeyboardContainerProps {
  currentClue: string;
  onLeftArrowClick: Function;
  onRightArrowClick: Function;
  onKeyboardLetterClick: Function;
}

export const KeyboardContainer: React.FC<KeyboardContainerProps> = (props: KeyboardContainerProps) => (
  <div className="keyboard-container">
    <ClueBar currentClue={props.currentClue} onLeftArrowClick={props.onLeftArrowClick} onRightArrowClick={props.onRightArrowClick} />
    <Keyboard onKeyboardLetterClick={props.onKeyboardLetterClick} />
  </div>
);

interface ClueBarProps {
  currentClue: string;
  onLeftArrowClick: Function;
  onRightArrowClick: Function;
}

const ClueBar: React.FC<ClueBarProps> = (props: ClueBarProps) => (
  <div className={"clue-bar-container"}>
    <div className={"clue-bar-arrow"} onClick={() => props.onLeftArrowClick()}>
      <FontAwesomeIcon icon={faChevronLeft} />
    </div>
    {props.currentClue}
    <div className={"clue-bar-arrow"} onClick={() => props.onRightArrowClick()}>
      <FontAwesomeIcon icon={faChevronRight} />
    </div>
  </div>
);

interface KeyboardProps {
  onKeyboardLetterClick: Function;
}

const Keyboard: React.FC<KeyboardProps> = (props: KeyboardProps) => (
  <div className={"keyboard"}>
    <KeyboardRow letters={"qwertyuiop"} onKeyboardLetterClick={props.onKeyboardLetterClick} />
    <KeyboardRow letters={"asdfghjkl"} onKeyboardLetterClick={props.onKeyboardLetterClick} />
    <KeyboardRow letters={"zxcvbnm<"} onKeyboardLetterClick={props.onKeyboardLetterClick} />
  </div>
);

interface KeyboardRowProps {
  letters: string;
  onKeyboardLetterClick: Function;
}

const KeyboardRow: React.FC<KeyboardRowProps> = (props: KeyboardRowProps) => (
  <div className={"keyboard-row"}>
    {props.letters.split("").map(function(letter: string, key: number) {
      return <KeyboardButton key={key} letter={letter} onKeyboardLetterClick={props.onKeyboardLetterClick} />
    })}
  </div>
);

interface KeyboardButtonProps {
  letter: string;
  onKeyboardLetterClick: Function;
}

// <div className={"clue-bar-arrow"} onClick={() => props.onRightArrowClick()}>
//       <FontAwesomeIcon icon={faChevronRight} />
//     </div>

const KeyboardButton: React.FC<KeyboardButtonProps> = (props: KeyboardButtonProps) => (
  <div className={"keyboard-button" + (props.letter === DELETE ? " delete-button" : "")  + (props.letter === 'z' ? " z-button" : "")} onClick={() => props.onKeyboardLetterClick(props.letter)}>
    {props.letter === DELETE ? <FontAwesomeIcon icon={faDeleteLeft} /> : props.letter.toUpperCase()}
  </div>
);