import { WordPyramidSolution } from "./word_pyramid_classes";

export const dailyPyramids = [
  new WordPyramidSolution([
    ["gin", "Juniper liquor"],
    ["nigh", "Approaching, in romantic poetry"],
    ["singh", "Indian prime minister before Modi"],
    ["hosing", "Watering by tube"],
    ["shooing", "Driving away"],
    ["shooting", "Like some pains"]
  ]),
  new WordPyramidSolution([
    ["dea", "Heroin-seizing org."],
    ['dare', '"I double ___ you!"'],
    ['garde', '"En ___" (fencer\'s cry)'],
    ['grader', 'Teacher, during exam week'],
    ['regards', 'Letter sign-off'],
    ['regrades', 'Changes a B to an A-']
  ]),
  new WordPyramidSolution([
    ["ate", "Enjoyed a meal"],
    ['rate', 'Review on Yelp, say'],
    ['alert', 'Keen-eyed'],
    ['retail', 'What one doesn\'t pay at a clearance sale'],
    ['article', 'Newspaper feature'],
    ['literacy', 'An aim of education']
  ]),
  new WordPyramidSolution([
    ["the", "Kermit's middle name?"],
    ['tech', 'Georgia or Cal.'],
    ['chest', 'Treasure container'],
    ['cheats', 'Has an ace up one\'s sleeve, say'],
    ['teaches', 'Fills kids\' heads with all sorts of stuff'],
    ['cheapest', 'Least likely to tip']
  ]),
  new WordPyramidSolution([
    ['stl', 'MLB\'s Cardinals, on scoreboards'],
    ['lust', 'One of the "deadly seven"'],
    ['gluts', 'Fills to the gills'],
    ['glutes', 'Squat muscles'],
    ['gluesto', 'Sticks on, in a way'],
    ['eulogist', 'One who makes passing remarks?']
  ]),
  new WordPyramidSolution([
    ["ore", "Mother lode load"],
    ["rote", "Mechanical memorization"],
    ["store", "Mom and pop enterprise"],
    ["forest", "Robin Hood's hangout"],
    ["sentfor", "Ordered by mail"],
    ["softener", "Laundry additive"],
  ]),
  new WordPyramidSolution([
    ["gsa", "School org. that might observe Day of Silence"],
    ["gias", "Model Carangi and actress Scala"],
    ["gains", "Puts on a few pounds"],
    ["saigon", "Name of Ho Chi Minh City until 1976"],
    ["soaring", "Emulating an eagle"],
    ["arousing", "Stimulating"],
  ]),
  new WordPyramidSolution([
    ["rub", "Cause some friction"],
    ["burs", "Prickly seed coverings"],
    ["bursa", "Bodily sac"],
    ["bursal", "Of a pouch, in anatomy"],
    ["belarus", "Home to tennis's Victoria Azarenka"],
    ["reusable", "Like tote bags and metal straws"],
  ]),
  new WordPyramidSolution([
    ["cos", "Trig. ratio"],
    ["cons", "Prison yard residents"],
    ["scorn", "Reject derisively"],
    ["crowns", "Headgear for queens and kings"],
    ["screwon", "Like some caps"],
    ["newscorp", "Murdoch's outfit (abbr)"],
  ]),
  new WordPyramidSolution([
    ["spr", "Sum. preceder"],
    ["pers", "The \"P\" in PIN: Abbr."],
    ["purse", "Small handbag"],
    ["pauser", "One who hesitates"],
    ["pasture", "Grassland"],
    ["upstater", "Resident of Buffalo, e.g."],
  ]),
  new WordPyramidSolution([
    ["war", "Deadly event that has used answer #3 since the stone age"],
    ["rowa", "Seats by the orchestra pit, perhaps"],
    ["arrow", "Weapon of answer #1"],
    ["marrow", "Bone filler"],
    ["warroom", "Center for strategic planning"],
    ["wardroom", "Naval officers' quarters"],
  ]),
  new WordPyramidSolution([
    ["gsa", "Cookie selling org., originally"],
    ["sang", "Emulated a canary"],
    ["snags", "Unexpected troubles"],
    ["angsts", "Anxiety episodes"],
    ["atsigns", "Parts of many modern addresses"],
    ["instages", "Step by step"],
  ]),
  new WordPyramidSolution([
    ["sat", "Held one's own in Musical Chairs"],
    ["mast", "Crows-nest spot"],
    ["meats", "Vegan's no-nos"],
    ["stamen", "Pollen-producing organ"],
    ["martens", "Bushy-tailed relatives of weasels"],
    ["remnants", "Traces"],
  ]),
  new WordPyramidSolution([
    ["bet", "Casino event"],
    ["beet", "Root vegetable"],
    ["beret", "Chanel chapeau"],
    ["beater", "Quidditch player equipped with a club"],
    ["rebater", "Discounter"],
    ["breather", "Short rest"],
  ]),
  new WordPyramidSolution([
    ["oat", "Type of bran"],
    ["oath", "Hippocratic, for one"],
    ["torah", "Bar mitzvah recital"],
    ["hotcar", "Stolen wheels"],
    ["chariot", "Transportation for Helios"],
    ["thoracic", "Of the chest"],
  ]),
  new WordPyramidSolution([
    ["uss", "Letters starting the Enterprise's name, in \"Star Trek\""],
    ["russ", "\"Let ___ cook\": popular Seahawks' fan mantra from 2019-2022"],
    ["truss", "Support for a bridge"],
    ["estrus", "Stage when an animal is in heat"],
    ["custers", "___ Last Stand"],
    ["scutters", "Bustles about."],
  ]),
  new WordPyramidSolution([
    ["ato", "\"From __ Zinc\": vitamin slogan"],
    ["nato", "Defense alliance based in Brussels"],
    ["notas", "___ much (less)"],
    ["anotes", "They're between G's and B's"],
    ["pasteon", "Attach with glue"],
    ["onespath", "Cross ___ (encounter)"],
  ]),
  new WordPyramidSolution([
    ["snl", "Long-running NBC program"],
    ["lens", "Microscope attachment"],
    ["leans", "Doesn't stand straight"],
    ["latens", "Approaches the wee hours"],
    ["lateens", "Triangular sails"],
    ["cleanest", "Most immaculate"],
  ]),
  new WordPyramidSolution([
    ["det", "The Tigers of the MLB"],
    ["estd", "\"Welcome to\" sign abbr."],
    ["dotes", "Is overly fond of, with \"on\""],
    ["doesit", "Words after that or easy"],
    ["osteoid", "Bonelike"],
    ["woodiest", "Most sylvan"],
  ]),
  new WordPyramidSolution([
    ["ina", "Words with \"jam\" or \"hurry\""],
    ["gina", "Rodriguez who starred in \"Jane the Virgin\""],
    ["ngaio", "New Zealander author Marsh"],
    ["gainon", "Begin to catch up to"],
    ["atoning", "Penitent's activity"],
    ["tangoing", "Dancing in an Argentinian style that \"it takes two\" to do"],
  ]),
  new WordPyramidSolution([
    ["dei", "Opus __: \"The Da Vinci Code\" sect"],
    ["dire", "Straits modifier"],
    ["cider", "Apple beverage"],
    ["deicer", "Fluid sprayed on planes"],
    ["deciare", "10 square meters"],
    ["ideclare", "\"___ bankruptcy!\": iconic Michael Scott quote"],
  ]),
  new WordPyramidSolution([
    ["ars", "\"__ Gratia Artis\""],
    ["bras", "These were burned in the '60s"],
    ["bears", "Gummy animals"],
    ["baster", "Tube used by a chef"],
    ["barters", "Does deals without money"],
    ["rebaters", "They give deductions"],
  ]),
  new WordPyramidSolution([
    ["sot", "Barfly"],
    ["toes", "Sandals' lack"],
    ["totes", "Pledge drive gifts"],
    ["tiesto", "Hooks up with"],
    ["toilets", "Facilities"],
    ["politest", "Exhibiting the most civility"],
  ]),
  new WordPyramidSolution([
    ["ces", "These, in Nice"],
    ["secs", "Stopwatch ticks: Abbr."],
    ["cress", "Pungent salad leaf"],
    ["casers", "Burglars checking out potential targets, say"],
    ["spacers", "Typewriter parts"],
    ["scrapers", "Road machines."],
  ]),
  new WordPyramidSolution([
    ["ocd", "Diagnosis characterized by repetitive behavior, in brief"],
    ["coda", "Award-winning 2022 movie featuring three deaf actors"],
    ["andco", "Corporate \"et al.\""],
    ["deacon", "Minister's helper"],
    ["actedon", "Did something about, as an informant's tip"],
    ["toedance", "Ballet routine"],
  ]),
  new WordPyramidSolution([
    ["dom", "King's end?"],
    ["doom", "Make toast?"],
    ["mooed", "Joined a stock exchange?"],
    ["mooted", "Brought up for debate"],
    ["outmode", "Make unfashionable"],
    ["movedout", "Left for college, perhaps"],
  ]),
  new WordPyramidSolution([
    ["ges", "Some washers and dryers, for short"],
    ["megs", "RAM units, for short"],
    ["mages", "Necromancers"],
    ["gamest", "Most willing"],
    ["itsagem", "\"That's precious!\""],
    ["tiegames", "Anticlimactic gridiron outcomes"],
  ]),
  new WordPyramidSolution([
    ["rat", "Chinese-year critter"],
    ["tyra", "Banks on some magazine covers"],
    ["teary", "About to cry"],
    ["eatery", "Greasy spoon"],
    ["theyear", "\"Person of _____\""],
    ["leathery", "Tough & weathered (skin)"],
  ]),
  new WordPyramidSolution([
    ["tag", "Freeze ___ (variant of a playground game)"],
    ["stag", "Male companion for a doe"],
    ["gates", "Posts for some security guards"],
    ["egesta", "Discharged matter"],
    ["gametes", "Sperm and ova"],
    ["jetsgame", "MetLife Stadium event"],
  ]),
  new WordPyramidSolution([
    ["rem", "Sleep study acronym"],
    ["term", "End of ___ paper"],
    ["muter", "Remote control, at times"],
    ["mature", "Ready for harvest"],
    ["tempura", "Deep-fried Japanese dish"],
    ["puremath", "College area of study with no application required?"],
  ]),
  new WordPyramidSolution([
    ["gee", "Golly!"],
    ["gene", "Kelly, Hackman, Wilder, etc."],
    ["genre", "Story category"],
    ["enrage", "To severely annoy"],
    ["agender", "Nonbinary identity"],
    ["underage", "17 in general, 20 for drinking in the United States"],
  ]),
  new WordPyramidSolution([
    ["oar", "One that rocks the boat?"],
    ["aero", "___space engineer"],
    ["adore", "Love love love"],
    ["roamed", "Incurred higher cellphone charges"],
    ["andmore", "'All that ___' (commerical ender)"],
    ["pardonme", "''Oops, sorry!''"],
  ]),
  new WordPyramidSolution([
    ["aed", "CPR accompanier, sometimes"],
    ["dare", "Triple dog follower"],
    ["eared", "Dog follower"],
    ["adhere", "Cling to"],
    ["beheard", "Have a voice"],
    ["breached", "Surfaced, as a whale"],
  ]),
  new WordPyramidSolution([
    ["tle", "Rat tail?"],
    ["teal", "Relative of turquoise"],
    ["aleut", "Language distantly related to Inuit"],
    ["amulet", "Evil-repelling charm"],
    ["emulate", "Copy a flightless bird?"],
    ["lugeteam", "Olympians who compete on their backs"],
  ]),
  new WordPyramidSolution([
    ["alt", "Computer key"],
    ["tale", "Something to weave or spin"],
    ["delta", "The Nile has one"],
    ["leadto", "Result in"],
    ["dealsto", "Gives, as some cards"],
    ["leotards", "Ballet garb"],
  ]),
  new WordPyramidSolution([
    ["rat", "Maze-running rodent"],
    ["tare", "Gross minus net (weight)"],
    ["rebat", "Go up to the plate again."],
    ["barter", "Negotiate a swap"],
    ["rebater", "Discounter"],
    ["barrette", "Hair ornament"],
  ]),
  new WordPyramidSolution([
    ["ich", "\"___ liebe dich\""],
    ["inch", "Part of a foot?"],
    ["chins", "Settings for goatees"],
    ["chains", "Shackles"],
    ["inchaos", "Completely jumbled"],
    ["chicanos", "Mexican-Americans"],
  ]),
  new WordPyramidSolution([
    ["art", "National Gallery attraction"],
    ["tear", "Shed a ___ (cry a little)"],
    ["later", "Procrastinator's reply"],
    ["antler", "Weapon for a reindeer"],
    ["eternal", "Continuing indefinitely"],
    ["lamenter", "Woes wailer"],
  ]),
  new WordPyramidSolution([
    ["tea", "What \"chai\" means"],
    ["meat", "Potpie ingredient"],
    ["steam", "You might lose it when you're tired"],
    ["stamen", "Pollen holder"],
    ["meansto", "Will, if intentions bear out"],
    ["montages", "Training sequences in movies, often"],
  ]),
  new WordPyramidSolution([
    ["ots", "Extra periods (Abbr.)"],
    ["sort", "Alphabetize"],
    ["frost", "Jack who nips at your nose, in a Christmas carol"],
    ["satfor", "Took, as a test"],
    ["formats", "Organizes"],
    ["farmsout", "Sends work to another shop"],
  ]),
  new WordPyramidSolution([
    ["eta", "Greek vowel that looks like the letter H"],
    ["feta", "Chunks in a Greek salad"],
    ["feast", "Cornucopia contents"],
    ["strafe", "Attack from a plane"],
    ["farwest", "Pacific coast area"],
    ["software", "Computer buy"],
  ]),
  new WordPyramidSolution([
    ["ser", "To be, in Madrid"],
    ["rose", "Romantic red flower"],
    ["morse", "Code with dits and dahs"],
    ["sermon", "Message from a pulpit"],
    ["moderns", "Contemporary people"],
    ["syndrome", "Villain in \"The Incredibles\""],
  ]),
  new WordPyramidSolution([
    ["sir", "Michael Caine's address"],
    ["rise", "Move higher"],
    ["shire", "U.S.: county :: U.K. : __"],
    ["riches", "From rags to ___"],
    ["coheirs", "Fortune sharers, perhaps"],
    ["choosier", "More particular"],
  ]),
  new WordPyramidSolution([
    ["nag", "Pester persistently"],
    ["gain", "Opposite of loss"],
    ["aping", "Mimic's ability"],
    ["taping", "Recording session"],
    ["pasting", "Typing Control + V"],
    ["paganist", "Wiccan, e.g."],
  ]),
  new WordPyramidSolution([
    ["elo", "Chess ranking system"],
    ["aloe", "Rash application"],
    ["amole", "Whac-___"],
    ["malone", "NBA legend Karl"],
    ["blameon", "Attribute to, in a way"],
    ["nobleman", "Lady's other half"],
  ]),
  new WordPyramidSolution([
    ["can", "Optimist's word"],
    ["cant", "Pessimist's word"],
    ["catan", "Settlement-building game, informally"],
    ["sancta", "Safe, private places"],
    ["catnaps", "Little siestas"],
    ["snapchat", "Instagram rival"],
  ]),
  new WordPyramidSolution([
    ["leg", "It may be shaken or pulled"],
    ["gels", "Bathing products"],
    ["gales", "Heavy winds"],
    ["eagles", "'Hotel California' band"],
    ["ageless", "Everlasting"],
    ["glasseye", "Taxidermist's item"],
  ]),
  new WordPyramidSolution([
    ["tad", "Wee bit"],
    ["toad", "Squat amphibian"],
    ["adout", "Tennis score after deuce, maybe"],
    ["hadout", "Lost, as tonsils"],
    ["handout", "Freebie"],
    ["touchand", "___ go"],
  ]),
  new WordPyramidSolution([
    ["eat", "Munch on"],
    ["tale", "Yarn"],
    ["alter", "Tweak, say"],
    ["taller", "Not so short"],
    ["literal", "Hardly figurative"],
    ["alltimer", "Something extraordinary that won't soon be forgotten"],
  ]),
  new WordPyramidSolution([
    ["ton", "A lot of pounds"],
    ["note", "It may be passed in class"],
    ["stone", "Boulder or pebble"],
    ["onsets", "Initial stages"],
    ["sonnets", "Shakespearean works"],
    ["tensions", "They may mount or ease"],
  ]),
  new WordPyramidSolution([
    ["las", "Articulo en español"],
    ["seal", "Flipper flapper"],
    ["heals", "Mends"],
    ["lashed", "Tied securely"],
    ["shoaled", "Full of sandbanks"],
    ["dogleash", "Pooch-walking need"],
  ]),
  new WordPyramidSolution([
    ["hot", "Really spicy, as some salsa"],
    ["host", "One taking coats, perhaps"],
    ["those", "Plural of that"],
    ["honest", "Like Abe"],
    ["hateson", "Derides, slangily"],
    ["havenots", "Deprived ones"],
  ]),
  new WordPyramidSolution([
    ["gis", "\"M*A*S*H\" extras"],
    ["gris", "Gray in Grosseliers"],
    ["grins", "Big smiles"],
    ["spring", "Jack-in-the box gizmo"],
    ["pursing", "Puckering, as lips"],
    ["perusing", "More than reviewing"],
  ]),
  new WordPyramidSolution([
    ["net", "Fishing aid"],
    ["tern", "Fork-tailed flier"],
    ["tuner", "One concerned with pitches"],
    ["tenure", "Professor's protection"],
    ["beernut", "Pub tidbit"],
    ["brunette", "Dark-haired one"],
  ]),
  new WordPyramidSolution([
    ["sus", "Pending starter?"],
    ["suis", "Part of être"],
    ["usies", "Arm's-length pics of oneself with friends, in modern slang"],
    ["sureis", "\"You got that right!\""],
    ["squires", "Knights' attendants"],
    ["esquires", "Gentlemen just below knights"],
  ]),
  new WordPyramidSolution([
    ["aol", "Big name on the Net"],
    ["oral", "Type of hygienist dealing with answer #3, perhaps"],
    ["molar", "Filling site"],
    ["morals", "What unscrupulous people lack"],
    ["normals", "Vectors perpendicular to tangents"],
    ["romulans", "\"Star Trek\" characters who are typically antagonistic"],
  ]),
  new WordPyramidSolution([
    ["ted", "___ Talks (expert speaker video series)"],
    ["date", "Both an activity and a fruit"],
    ["toeda", "___ line (conformed)"],
    ["doaset", "Complete one exercise segment"],
    ["toasted", "Warmed by the fire"],
    ["tastedof", "Suggested, flavorwise"],
  ]),
  new WordPyramidSolution([
    ["sel", "Salt, in Paris"],
    ["gels", "Hair-holding goops"],
    ["glens", "Secluded locations"],
    ["single", "\"___ and ready to mingle!\""],
    ["shingle", "Rafter neighbor"],
    ["fleshing", "Giving substance to, with \"out\""],
  ]),
  new WordPyramidSolution([
    ["lam", "Go on the ___ (be a runaway)"],
    ["slam", "Bang shut"],
    ["clams", "Chowder ingredients"],
    ["camels", "Mounts for sheiks"],
    ["calmest", "Rattled the least"],
    ["camelast", "Arrived the most fashionably?"],
  ]),
  new WordPyramidSolution([
    ["rad", "\"Far out, dude!\""],
    ["drab", "Lacking in brightness"],
    ["brand", "Lego or Eggo, for example"],
    ["brenda", "Disney Channel Song"],
    ["broaden", "Expand, as one's horizons"],
    ["debonair", "Sophisticatedly charming"],
  ]),
  new WordPyramidSolution([
    ["eos", "Lover of Orion, in myth"],
    ["ecos", "Reverberations in Spain"],
    ["coves", "Sheltered bays"],
    ["voices", "Tenor and bass"],
    ["novices", "They're new at it"],
    ["connives", "Plots slyly"],
  ]),
  new WordPyramidSolution([
    ["tie", "Item with a clip or a pin"],
    ["edit", "Rewrite puzzle clues, say"],
    ["deist", "Leonardo da Vinci, religiously"],
    ["driest", "Least moist"],
    ["dustier", "More in need of housecleaning"],
    ["squirted", "Used a water gun"],
  ]),
  new WordPyramidSolution([
    ["ray", "Beam (of light)"],
    ["tray", "Carry-all in a cafeteria"],
    ["tarry", "Dillydally"],
    ["rotary", "Like some phones and lawn mowers"],
    ["carroty", "Orangish"],
    ["storyarc", "Narrative through multiple TV episodes"],
  ]),
  new WordPyramidSolution([
    ["dos", "A couple in Cancún?"],
    ["sold", "Word heard at an auction"],
    ["lodes", "Ore-rich deposits"],
    ["oodles", "Gazillions"],
    ["oldfoes", "Enemies from way back"],
    ["refloods", "Submerges again"],
  ]),
  new WordPyramidSolution([
    ["poe", "The Raven, poet"],
    ["rope", "Something to skip for exercise"],
    ["prose", "Novel material"],
    ["osprey", "Seabird"],
    ["leprosy", "Reason to be shunned, in the Bible"],
    ["pryloose", "Free, as with a crowbar"],
  ]),
  new WordPyramidSolution([
    ["sac", "Kangaroo pouch, for one"],
    ["case", "Ukulele accessory"],
    ["caste", "Title of Isabel Wilkerson book"],
    ["cuteas", "___ a button"],
    ["nutcase", "Wackaloon"],
    ["unchaste", "Impure"],
  ]),
  new WordPyramidSolution([
    ["sir", "Herr, here"],
    ["rise", "Obey a court order"],
    ["pries", "Works loose with a crowbar"],
    ["stripe", "Symbol of rank"],
    ["restrip", "Get back to the original wood again"],
    ["spritzer", "Wine + soda drink"],
  ]),
  new WordPyramidSolution([
    ["tse", "Mao ________ -tung"],
    ["site", "pyramini.com, for one"],
    ["inset", "Map within map"],
    ["tennis", "Sport on a court"],
    ["nesting", "Like some birds or dolls"],
    ["scenting", "Bloodhound's skill"],
  ]),
  new WordPyramidSolution([
    ["sin", "God hates it"],
    ["ions", "Particles in a redox reaction"],
    ["noise", "Statistician's problem"],
    ["senior", "Alumnus-to-be"],
    ["cronies", "Gang members"],
    ["conspire", "Plot in secrecy"],
  ]),
  new WordPyramidSolution([
    ["sad", "Morose"],
    ["sand", "Beach, basically"],
    ["hands", "Ones working around the clock?"],
    ["danish", "Croissant alternative"],
    ["headsin", "Goes home for the night, say"],
    ["shandies", "Beer-lemonade drinks"],
  ]),
  new WordPyramidSolution([
    ["pie", "Slice left over from Thanksgiving, say"],
    ["ripe", "Finished growing on the vine"],
    ["peril", "Thin ice, to a skater"],
    ["ripple", "Small wave"],
    ["slipper", "Glass item in \"Cinderella\""],
    ["flippers", "Seal quartet"],
  ]),
  new WordPyramidSolution([
    ["are", "\"We __ not amused\""],
    ["care", "Worry"],
    ["crate", "Petco purchase"],
    ["redact", "Censor from a document"],
    ["redcoat", "Minuteman's foe"],
    ["democrat", "Ballot choice"],
  ]),
  new WordPyramidSolution([
    ["set", "Word with train or jet"],
    ["lest", "Before \"we forget\""],
    ["steel", "Rebar material"],
    ["eldest", "Firstborn sibling"],
    ["sleeted", "Precipitated in winter, in a way"],
    ["tweedles", "Sings like a bird"],
  ]),
  new WordPyramidSolution([
    ["ani", "\"Gimme ___! ...\" (Iowa State cheer)"],
    ["sain", "Healthy, in Paris"],
    ["nesia", "Ending with Poly or Indo"],
    ["inasec", "\"Soon as I'm done here!\""],
    ["cavesin", "Folds under pressure"],
    ["vaccines", "Some shots"],
  ]),
  new WordPyramidSolution([
    ["mea", "\"Dominus illuminatio ___\" (Oxford University's motto)"],
    ["mare", "Foal's mom"],
    ["armed", "With weapons"],
    ["dreams", "Ambitions"],
    ["smarted", "Stung"],
    ["mustread", "Book not to be missed"],
  ]),
  new WordPyramidSolution([
    ["sat", "Precollege hurdle, for short"],
    ["taos", "City or county of New Mexico"],
    ["coast", "Shoreline"],
    ["acosta", "CNN journalist Jim"],
    ["atacost", "How Pyrrhic victories are won"],
    ["staccato", "\"Choppy\" musical direction"],
  ]),
  new WordPyramidSolution([
    ["roc", "Enormous bird of myth"],
    ["orca", "Creature in an underwater pod"],
    ["actor", "One pleased with long lines?"],
    ["costar", "Lead-sharing answer #3"],
    ["carrots", "Rabbit food"],
    ["curators", "Museum overseers"],
  ]),
  new WordPyramidSolution([
    ["ail", "Not feel so hot"],
    ["laic", "Non-clergy"],
    ["lilac", "Purple bloom"],
    ["scilla", "Counterpart of Charybdis in Greek Mythology"],
    ["callins", "AM radio participants"],
    ["callsign", "A wireless operator's identification"],
  ]),
  new WordPyramidSolution([
    ["sip", "Take in tentatively"],
    ["rips", "Places for patches"],
    ["crisp", "A potato chip, in London"],
    ["script", "Playwright's output"],
    ["tropics", "Capricorn and Cancer"],
    ["apricots", "Fruits that are the basis of Marillenschnaps"],
  ]),
  new WordPyramidSolution([
    ["den", "Forest animal's lair"],
    ["nerd", "Trivia night champion, perhaps"],
    ["drone", "Boring speaking voice"],
    ["snored", "Annoyed a bedmate, maybe"],
    ["rednose", "Christmas light?"],
    ["worsened", "Went downhill"],
  ]),
  new WordPyramidSolution([
    ["son", "Thor, to Odin"],
    ["eons", "Stretches of history"],
    ["asone", "In complete agreement"],
    ["easeon", "Press lightly, as the brakes"],
    ["onecase", "24 bottles of beer"],
    ["noescape", "Black-hole enterer's prospect"],
  ]),
  new WordPyramidSolution([
    ["lap", "Seat for toddlers"],
    ["plea", "\"Not guilty,\" for example"],
    ["penal", "Type of code"],
    ["alpine", "Like Swiss mountains"],
    ["leaping", "Bounding"],
    ["pleasing", "Agreeable"],
  ]),
  new WordPyramidSolution([
    ["red", "Kind of herring"],
    ["reed", "Clarinet's vibrator"],
    ["freed", "Let loose"],
    ["nerfed", "Weakened, in gaming slang"],
    ["refined", "Not coarse"],
    ["redefine", "Set new boundaries"],
  ]),
  new WordPyramidSolution([
    ["oil", "Snake ___ (hooey)"],
    ["soil", "Nitrogen source for plants"],
    ["lions", "Animals that travel in a pride"],
    ["tonsil", "Throat part"],
    ["lotions", "Skin creams"],
    ["solution", "Liquid mixture of two or more substances"],
  ]),
  new WordPyramidSolution([
    ["gin", "Partner of tonic, to a bartender"],
    ["sign", "It's read while driving"],
    ["sling", "Broken arm protector"],
    ["single", "Album highlight"],
    ["lingers", "Keeps the host up, perhaps"],
    ["gremlins", "Unseen troublemakers"],
  ]),
  new WordPyramidSolution([
    ["orc", "Evildoer in Tolkien tales"],
    ["orca", "Dolphin's predator"],
    ["acorn", "Item in a fall stash"],
    ["aircon", "House cooler, for short"],
    ["ocarina", "\"The Legend of Zelda: ___ of Time\""],
    ["macaroni", "Elbow-shaped pasta, for instance"],
  ]),
  new WordPyramidSolution([
    ["lei", "\"Aloha\" gift"],
    ["tile", "Bathroom wall-covering, often"],
    ["filet", "Mignon lead-in"],
    ["trifle", "Insignificant detail"],
    ["flirted", "Was coquettish"],
    ["filtered", "Strained"],
  ]),
  new WordPyramidSolution([
    ["rue", "Wish one could undo"],
    ["sure", "Word with fire or thing"],
    ["trues", "Puts into alignment"],
    ["uterus", "Spot for an IUD"],
    ["usroute", "American hwy. number lead-in"],
    ["coutures", "Designers' lines"],
  ]),
  new WordPyramidSolution([
    ["met", "Satisfied, as a quota"],
    ["time", "Watch readout"],
    ["smite", "Strike down, in the Bible"],
    ["kismet", "Fatalist's fate"],
    ["makesit", "Reaches the big time"],
    ["mistakes", "Misunderstands"],
  ]),
  new WordPyramidSolution([
    ["sri", "\"Lanka\" front"],
    ["sire", "Filly's father"],
    ["resin", "Violin rub-on"],
    ["senior", "Older in years"],
    ["ironers", "Wrinkle removers"],
    ["prisoner", "\"Harry Potter and the ___ of Azkaban\""],
  ]),
  new WordPyramidSolution([
    ["lat", "Opp. of longitude"],
    ["salt", "Flavor enhancer"],
    ["lotsa", "Numerous, informally"],
    ["oswalt", "Actor and stand-up comic Patton"],
    ["outlaws", "Western bad guys"],
    ["walksout", "Leaves a building"],
  ]),
  new WordPyramidSolution([
    ["ins", "Networking can net them"],
    ["gins", "Liquors in martinis"],
    ["singe", "Burn, as hair"],
    ["single", "Infield hit, usually"],
    ["lingers", "Sticks around"],
    ["slingers", "Tossers, usually of mud"],
  ]),
  new WordPyramidSolution([
    ["ace", "Half of a blackjack"],
    ["acue", "Take ___ from (use for guidance)"],
    ["cause", "What an activist has"],
    ["clause", "Lease provision"],
    ["unlaces", "Loosens, as a corset"],
    ["cleansup", "Rakes it in"],
  ]),
  new WordPyramidSolution([
    ["use", "Profit from"],
    ["sure", "Indisputable"],
    ["rules", "List in a board game box"],
    ["rubles", "Moscow moolah"],
    ["subtler", "More nuanced"],
    ["troubles", "Inconveniences"],
  ]),
  new WordPyramidSolution([
    ["but", "Objecting word"],
    ["tube", "Lipstick holder"],
    ["brute", "Ill-mannered sort"],
    ["rebuts", "Refutes"],
    ["bunters", "They make sacrifices"],
    ["urbanest", "Most refined and sophisticated"],
  ]),
  new WordPyramidSolution([
    ["rip", "Split apart"],
    ["pair", "Shoe-store buy"],
    ["aripe", "Live to ___ old age"],
    ["pirate", "Brigand"],
    ["primate", "Subject of evolutionary study"],
    ["imparted", "Made known, as wisdom"],
  ]),
  new WordPyramidSolution([
    ["sir", "Post-knighting title"],
    ["rise", "Get ready to shine?"],
    ["siren", "Traffic-stopping sound"],
    ["singer", "Crooner"],
    ["iresign", "Words spoken in a huff, perhaps"],
    ["stingier", "Miser compared to an average Joe"],
  ]),
  new WordPyramidSolution([
    ["end", "Maze bottom word"],
    ["send", "Mail, as holiday cards"],
    ["snide", "Derogatory, as remarks"],
    ["indies", "West ___, 1492 \"discovery\""],
    ["insider", "One who knows the scoop"],
    ["residing", "Dwelling"],
  ]),
  new WordPyramidSolution([
    ["sam", "\"Green Eggs and Ham\" character"],
    ["mars", "Twix candy maker"],
    ["roams", "Moves around"],
    ["orgasm", "Exciting conclusion?"],
    ["isogram", "Word with no repeating letters"],
    ["organism", "Life form"],
  ]),
  new WordPyramidSolution([
    ["roe", "Beluga eggs, e.g."],
    ["robe", "Wizard garb"],
    ["bores", "Tedious talkers"],
    ["ribose", "Sugar that's a component of RNA"],
    ["boilers", "Home heating sources"],
    ["borealis", "Aurora ___"],
  ]),
  new WordPyramidSolution([
    ["tie", "Reason to play more"],
    ["tire", "Pressurized air container"],
    ["rivet", "Fasten, as metal"],
    ["strive", "Make huge efforts"],
    ["privets", "Hedge shrubs"],
    ["overtips", "Leaves a lot on the table?"],
  ]),
  new WordPyramidSolution([
    ["leb", "Isr. neighbor"],
    ["lube", "Engine need"],
    ["blues", "The \"B\" of R&B"],
    ["sublet", "Rent out your rental"],
    ["unbelts", "Starts disrobing, perhaps"],
    ["bluntest", "Most frank"],
  ]),
  new WordPyramidSolution([
    ["esc", "Key for getting out"],
    ["ices", "Lays on, as buttercream"],
    ["rices", "Paddy products"],
    ["ceries", "Color that means \"cherry\""],
    ["cheries", "French darlings"],
    ["heretics", "Religious dissenters"],
  ]),
  new WordPyramidSolution([
    ["err", "Goof or go off"],
    ["ruer", "Penitent sort"],
    ["recur", "Come again?"],
    ["curers", "Preservers"],
    ["scourer", "Brillo pad, for one"],
    ["recourse", "Right to demand payment"],
  ]),
  new WordPyramidSolution([
    ["hot", "Tamale or dog"],
    ["host", "Stephen Colbert, for answer #6"],
    ["ethos", "Fundamental values"],
    ["tahoes", "Chevy S.U.V.'s"],
    ["loathes", "Can't stomach"],
    ["lateshow", "Ed Sullivan Theater production"],
  ]),
  new WordPyramidSolution([
    ["rad", "Cool, retro"],
    ["read", "Text message status"],
    ["heard", "Got from the grapevine"],
    ["adhere", "Stick to"],
    ["redhead", "Like Carrot Top"],
    ["helddear", "Was fond of"],
  ]),
  new WordPyramidSolution([
    ["rei", "Bonn : König :: Lisbon : __"],
    ["rein", "Part of a bridle"],
    ["rinse", "Wash sans soap"],
    ["resign", "Quit"],
    ["erasing", "Removing completely"],
    ["earrings", "Hoop jewelry"],
  ]),
  new WordPyramidSolution([
    ["mas", "\"Live ___\" (Taco Bell slogan)"],
    ["slam", "Poem-reading competition"],
    ["malls", "Santas' workplaces"],
    ["llamas", "Pals of alpacas"],
    ["smallad", "Tiny plug"],
    ["mallards", "Green-headed water birds"],
  ]),
  new WordPyramidSolution([
    ["cat", "Tabby or calico"],
    ["taco", "Mexican cuisine staple"],
    ["actor", "Stage performer"],
    ["cuatro", "Cinco menos uno"],
    ["outrace", "Beat to the finish"],
    ["educator", "One with a teaching degree"],
  ]),
  new WordPyramidSolution([
    ["nie", "Never, to Hans"],
    ["sine", "Oscilloscope wave"],
    ["resin", "Tree exudation"],
    ["shrine", "Holy sanctuary"],
    ["hernias", "Some ruptures, often involving intestines"],
    ["banisher", "One exiling"],
  ]),
  new WordPyramidSolution([
    ["nee", "Eleanor Roosevelt, ___ Roosevelt"],
    ["seen", "Glimpsed"],
    ["sneer", "Utter with contempt"],
    ["renews", "Doesn't let lapse"],
    ["newuser", "One who might have to create an account"],
    ["weresunk", "\"It's hopeless for us!\""],
  ]),
  new WordPyramidSolution([
    ["tan", "What a beachgoer may strive for"],
    ["ants", "\"Farm\" animals"],
    ["stain", "Deck treatment"],
    ["trains", "Stereotypical boy toys"],
    ["artisan", "Mosaicist or glassblower"],
    ["martians", "Red Planet residents"],
  ]),
  new WordPyramidSolution([
    ["par", "___ for the course (normal)"],
    ["tarp", "Something for a rainy day"],
    ["tapir", "Rubbery-nosed ungulate"],
    ["pirate", "Copyright violator"],
    ["pethair", "Carpet-cleaning challenge"],
    ["piechart", "Analyst's infographic"],
  ]),
  new WordPyramidSolution([
    ["cro", "____ -Magnon"],
    ["core", "Apple seeds' location"],
    ["score", "Twenty of something"],
    ["censor", "One might remove a curse"],
    ["scoreon", "Get a point against"],
    ["coronets", "Small crowns"],
  ]),
  new WordPyramidSolution([
    ["gid", "Sheep malady"],
    ["idig", "Hipster's \"gotcha\""],
    ["digit", "One, for one"],
    ["tiding", "Supporting temporarily, with 'over'"],
    ["dieting", "Skipping dessert, maybe"],
    ["tiedying", "Making psychedelic, in a way"],
  ]),
  new WordPyramidSolution([
    ["die", "Run out of power"],
    ["side", "Any of a nonagon's nine"],
    ["slide", "Chute"],
    ["wields", "Brandishes"],
    ["wildest", "\"Not in your ___ dreams!\""],
    ["twiddles", "Plays with one's thumbs, in a way"],
  ]),
  new WordPyramidSolution([
    ["ons", "Iron-___ (some patches)"],
    ["nosh", "Chomp on chips, say"],
    ["hasno", "-- use for (can't utilize)"],
    ["ohsnap", "Playful response to a good insult"],
    ["harpson", "Won't let go of"],
    ["harpoons", "Whaling spears"],
  ]),
  new WordPyramidSolution([
    ["sci", "Biol. or chem"],
    ["pics", "Brief shots?"],
    ["spice", "Cumin or cardamom"],
    ["septic", "Household tank"],
    ["triceps", "Muscle of the upper arm"],
    ["pitcrews", "Car refuelers during races"],
  ]),
  new WordPyramidSolution([
    ["loa", "Mauna ___ (1984 erupter)"],
    ["goal", "Score for Mia Hamm"],
    ["gloat", "Express schadenfreude"],
    ["legato", "Smoothly, as for a sonata"],
    ["tangelo", "Hybrid citrus fruit"],
    ["octangle", "Stop sign shape"],
  ]),
  new WordPyramidSolution([
    ["are", "\"What ___ you, nuts?\""],
    ["fare", "Cab's tab"],
    ["safer", "More protected"],
    ["earsof", "___ corn"],
    ["goesfar", "Makes it big"],
    ["gofaster", "\"Speed it up!\""],
  ]),
  new WordPyramidSolution([
    ["ras", "Dorm figures, for short"],
    ["scar", "Simba's sinister uncle"],
    ["oscar", "Grouchy Sesame Street denizen"],
    ["corals", "Atoll material"],
    ["carlots", "Auto dealerships"],
    ["locators", "Some ankle bracelets"],
  ]),
  new WordPyramidSolution([
    ["nit", "Petty quibble"],
    ["tins", "Sardines containers"],
    ["stain", "Tide target"],
    ["saints", "They may come marching in"],
    ["sustain", "Support continuously"],
    ["tsunamis", "Devastating waves"],
  ]),
  new WordPyramidSolution([
    ["tea", "Djarling or Pekoe"],
    ["teal", "Hue darker than aqua"],
    ["elate", "Spread a little joy, e.g."],
    ["relate", "Feel the same way"],
    ["getreal", "\"Oh, be serious!\""],
    ["regulate", "Control, as temperature"],
  ]),
  new WordPyramidSolution([
    ["rid", "Get ___ of (throw away)"],
    ["dirt", "Stuff of scandal"],
    ["tired", "Ready for a good night's sleep"],
    ["stride", "Marathoner's concern"],
    ["dlister", "Celebrity ... but just barely"],
    ["diluters", "Thinning agents"],
  ]),
  new WordPyramidSolution([
    ["set", "Filming location"],
    ["nest", "Home for hawks"],
    ["stone", "Slingshot missile"],
    ["stolen", "Nicked"],
    ["shelton", "Country singer Blake who's a coach on \"The Voice\""],
    ["neoliths", "'Answer #3' Age tools"],
  ]),
  new WordPyramidSolution([
    ["irk", "Get under the skin of"],
    ["risk", "Daredevil's desire"],
    ["skirt", "Evade, as an issue"],
    ["tricks", "Parts of hearts hands"],
    ["tickers", "They often run on cable news shows"],
    ["stickler", "Unyieldingly insistent type"],
  ]),
  new WordPyramidSolution([
    ["sia", "Chandelier singer"],
    ["isas", "''Beauty ___ beauty does''"],
    ["sails", "___ the seven seas (travels by ship)"],
    ["snails", "Spiral-shelled mollusks"],
    ["signals", "They may get mixed in communication"],
    ["lassoing", "Roping (cattle)"],
  ]),
  new WordPyramidSolution([
    ["top", "Shirt or blouse"],
    ["port", "Left on a cruise"],
    ["trope", "\"Found family\", for example"],
    ["deport", "Expel (from the country)"],
    ["torpedo", "Underwater weapon"],
    ["promoted", "Advanced in rank"],
  ]),
  new WordPyramidSolution([
    ["see", "Go visit"],
    ["ease", "Relaxation"],
    ["cease", "Bring to an end"],
    ["crease", "Fold or wrinkle"],
    ["searace", "Yachting event"],
    ["rarecase", "Uncommon example"],
  ]),
  new WordPyramidSolution([
    ["lad", "Little man"],
    ["lead", "Detective's clue"],
    ["dealt", "Began a poker hand"],
    ["dilate", "Expand, as pupils"],
    ["details", "Enumerates"],
    ["tailends", "Concluding parts"],
  ]),
  new WordPyramidSolution([
    ["tad", "Itty bit"],
    ["date", "Romantic appointment"],
    ["cadet", "Military school student"],
    ["acedit", "Nailed a test"],
    ["icedtea", "Brewed drink"],
    ["dedicate", "Offer formally"],
  ]),
  new WordPyramidSolution([
    ["nom", "Happy eating word"],
    ["moan", "Haunted house sound"],
    ["amino", "Type of acid in proteins"],
    ["camino", "El ___ (old Chevy)"],
    ["inacoma", "Out for a while"],
    ["anatomic", "Body-based"],
  ]),
  new WordPyramidSolution([
    ["rad", "Phat"],
    ["dark", "Somber shade"],
    ["raked", "Did a leaf-gathering job"],
    ["dekare", "Unit of area"],
    ["reasked", "Inquired anew"],
    ["kneaders", "Dough handlers."],
  ]),
  new WordPyramidSolution([
    ["ion", "Atomic structure"],
    ["coin", "\"Heads or tails?\" item"],
    ["tonic", "Gin's mate"],
    ["action", "Movie genre with car chases"],
    ["coating", "Varnish, e.g."],
    ["locating", "Finding"],
  ]),
  new WordPyramidSolution([
    ["rim", "Part of a basketball hoop"],
    ["trim", "Far from fat"],
    ["mirth", "Laughter-filled amusement"],
    ["hermit", "Solitary sort"],
    ["imthere", "Enthusiastic response to an invitation"],
    ["theremin", "Electronic musical instrument"],
  ]),
  new WordPyramidSolution([
    ["nip", "Small bite"],
    ["pain", "Acute discomfort"],
    ["piano", "Grand on stage"],
    ["onatip", "Because someone clued you in"],
    ["payinto", "Provide money for"],
    ["ponytail", "Braid relative"],
  ]),
  new WordPyramidSolution([
    ["rot", "Decay away"],
    ["rote", "Mindless memorization"],
    ["voter", "Presidential chooser"],
    ["vetoer", "Presidential nixer"],
    ["overeat", "Snack too much"],
    ["elevator", "Alternative to stairs"],
  ]),
  new WordPyramidSolution([
    ["mno", "L-P filler"],
    ["meno", "\"Ask ___ questions . . . \""],
    ["onemi", "1,760 yds."],
    ["ontime", "Prompt way to arrive"],
    ["emotion", "Anger or fear"],
    ["comeinto", "Acquire by accident"],
  ]),
  new WordPyramidSolution([
    ["lie", "Get in a prone position"],
    ["isle", "Bit of land in a sea"],
    ["slide", "Trombone piece"],
    ["yields", "Capitulates"],
    ["eyelids", "Open-and-shut facial features"],
    ["speedily", "Hurriedly"],
  ]),
  new WordPyramidSolution([
    ["den", "Winter quarters"],
    ["need", "Scholarship basis, often"],
    ["ended", "Done with"],
    ["deaden", "Quiet"],
    ["amended", "Fixed up"],
    ["dampened", "Prepared sheets for ironing"],
  ]),
  new WordPyramidSolution([
    ["gem", "Fine stone"],
    ["megs", "Computer drive capacity, in slang"],
    ["mages", "Wizards of old"],
    ["gamers", "Nintendo fans"],
    ["mirages", "Sahara sights"],
    ["epigrams", "Clever sayings"],
  ]),
  new WordPyramidSolution([
    ["gta", "Controversial driving video game, briefly"],
    ["tang", "Supply on old spaceships"],
    ["agent", "Star's go-between"],
    ["agnate", "Paternally related"],
    ["vantage", "____ point, allowing a clear view"],
    ["navigate", "Emulate Sulu on \"Star Trek\""],
  ]),
  new WordPyramidSolution([
    ["pet", "Kind of peeve"],
    ["pelt", "Hit, as with hailstones"],
    ["letup", "Abate, as rainfall"],
    ["poulet", "Chicken, in Contrecoeur"],
    ["octuple", "Multiply by eight"],
    ["couplets", "Pairs of rhyming rap lyrics"],
  ]),
  new WordPyramidSolution([
    ["ten", "Perfect, on the parallel bars"],
    ["sent", "Dispatched"],
    ["tense", "Grammarian's concern"],
    ["events", "Significant occasions"],
    ["sevento", "53 minutes past the hour"],
    ["lovenest", "Illicit rendezvous locale"],
  ]),
  new WordPyramidSolution([
    ["man", "Only creature that blushes"],
    ["mane", "King of the jungle's crowning glory"],
    ["names", "Some people drop them"],
    ["seamen", "All hands on deck?"],
    ["amnesia", "Knock-on-the-noggin consequence"],
    ["saidamen", "Ended a prayer"],
  ]),
  new WordPyramidSolution([
    ["sud", "Where Nice is en France"],
    ["used", "Like lots of items posted on Craigslist"],
    ["feuds", "Clan clashes"],
    ["surfed", "Caught some waves"],
    ["defuser", "Bomb ___ (hazardous job)"],
    ["dustfree", "Clean, as a shelf"],
  ]),
  new WordPyramidSolution([
    ["eta", "One might be postponed because of traffic"],
    ["late", "Stuck in traffic, perhaps"],
    ["petal", "Rosy projection?"],
    ["palate", "Taster's sense of taste"],
    ["leapsat", "Goes for enthusiastically"],
    ["placates", "Appeases"],
  ]),
  new WordPyramidSolution([
    ["mat", "Wrestler's canvas"],
    ["mate", "Finale for Fischer"],
    ["tamed", "Made less wild"],
    ["tandem", "It has two wheels and four pedals"],
    ["notmade", "Like a bed at times"],
    ["nematode", "Roundworm or eelworm"],
  ]),
  new WordPyramidSolution([
    ["sat", "Rode the bench, in the NBA"],
    ["east", "NBA conference"],
    ["asset", "Height, to an NBA player, e.g."],
    ["teases", "Flirts with"],
    ["estates", "Lands"],
    ["restates", "Says in another way"],
  ]),
  new WordPyramidSolution([
    ["roe", "Aquafarm product"],
    ["pore", "Skin opening"],
    ["repot", "Transfer, as a house plant"],
    ["report", "Homework assignment"],
    ["procter", "Gamble's partner"],
    ["receptor", "An eye, ear or nose, e.g."],
  ]),
  new WordPyramidSolution([
    ["sim", "Computer game avatar since 2000"],
    ["miss", "Not hit the target"],
    ["semis", "Finals' preceders"],
    ["remiss", "Doing a lousy job"],
    ["mossier", "More bryophytic"],
    ["misdoers", "Criminals"],
  ]),
  new WordPyramidSolution([
    ["foo", "First name of a nursery rhyme bunny"],
    ["goof", "Lead in to ball and around"],
    ["forgo", "Go without, intentionally"],
    ["forgot", "Went without, unintentionally"],
    ["goforit", "Give your all"],
    ["poorgift", "Hairbrush for a bald person, eg."],
  ]),
  new WordPyramidSolution([
    ["lap", "Drink (up), as a dog"],
    ["pale", "Drink, with ale"],
    ["pearl", "Boba shape"],
    ["palmer", "Drink, with Arnold"],
    ["trample", "Squash"],
    ["palmtree", "Sign of a desert oasis, perhaps"],
  ]),
  new WordPyramidSolution([
    ["tin", "The only 3 letter element"],
    ["mint", "Ryan Reynolds’ ___ Mobile"],
    ["imnot", "___ the only one"],
    ["motion", "Movie genre, with \"stop\""],
    ["emotion", ":) or :/ or :("],
    ["emoticon", ":) or :/ or :("],
  ]),
  new WordPyramidSolution([
    ["led", "Took the reins"],
    ["deal", "Start a poker game"],
    ["laden", "Burdened (with)"],
    ["leaned", "Wasn't straight"],
    ["seeland", "Spot the shoreline from a ship"],
    ["unsealed", "Opened, as an envelope"],
  ]),
  new WordPyramidSolution([
    ["end", "When credits roll"],
    ["dine", "Chow down uptown"],
    ["snide", "Sarcastic, as remarks"],
    ["undies", "Boxers, perhaps"],
    ["insured", "Protected against loss"],
    ["situnder", "Enjoy, as a shade tree"],
  ]),
  new WordPyramidSolution([
    ["sit", "Plop into a chair"],
    ["silt", "River residue"],
    ["tiles", "There are 100 in a Scrabble set"],
    ["elites", "Privileged ones"],
    ["tieless", "Dressed for work, but without neckwear"],
    ["tireless", "Energetic"],
  ]),
  new WordPyramidSolution([
    ["ein", "A, to the Germans"],
    ["nice", "Southern city, to the French"],
    ["mince", "Type of pie, to the British"],
    ["income", "___ tax return"],
    ["incomer", "Immigrant, to the British"],
    ["intercom", "Office hookup"],
  ]),
  new WordPyramidSolution([
    ["ats", "@@@@@"],
    ["taos", "New Mexico art hub"],
    ["iotas", "Certain Greek vowels"],
    ["patios", "House add-ons"],
    ["inaspot", "Faced with a predicament"],
    ["appoints", "Designates"],
  ]),
  new WordPyramidSolution([
    ["net", "Acrobat's lifesaver"],
    ["lent", "Mardi Gras aftermath"],
    ["knelt", "Prepared to engage?"],
    ["tinkle", "Sound of a little bell"],
    ["tolkien", "Middle-earth creator"],
    ["linkedto", "Possibly a result of"],
  ]),
  new WordPyramidSolution([
    ["sol", "Luna's counterpart"],
    ["lols", "Chuckles, slangily"],
    ["lysol", "Disinfecting Wipes brand"],
    ["alloys", "Metallic mixes"],
    ["layslow", "Waits for the heat to die down"],
    ["tollways", "Roads to avoid, for a free ride"],
  ]),
  new WordPyramidSolution([
    ["tub", "Rubber duck habitat"],
    ["stub", "Ticket remainder"],
    ["bunts", "Makes a softball sacrifice, perhaps"],
    ["bustin", "Enter forcefully"],
    ["busting", "Getting 22 in blackjack, for example"],
    ["bursting", "___ at the seams"],
  ]),
  new WordPyramidSolution([
    ["eds", "Hosp. areas"],
    ["sped", "Went 10 over"],
    ["dupes", "Swindles"],
    ["pulsed", "Used a blender, perhaps"],
    ["slurped", "Drank disruptively"],
    ["plunders", "Pillages"],
  ]),
  new WordPyramidSolution([
    ["car", "Beetle or wagon"],
    ["acro", "___ yoga"],
    ["actor", "Script reader"],
    ["carrot", "Good for your eyes, according to urban mythology"],
    ["creator", "Maker of content"],
    ["mercator", "Answer #5 of Euro-centric projection"],
  ]),
  new WordPyramidSolution([
    ["rut", "Deep tire track"],
    ["rust", "Coating on a coin"],
    ["trust", "Word on a coin"],
    ["truths", "They may be self-evident"],
    ["thrusts", "Pushes hard"],
    ["shutters", "Window covering"],
  ]),
  new WordPyramidSolution([
    ["log", "Rustic ski cabin component"],
    ["slog", "Long, hard work"],
    ["golds", "Desired medals"],
    ["lodges", "Rustic ski cabins"],
    ["dogslie", "\"Let sleeping ___\""],
    ["goeswild", "Throws caution to the wind"],
  ]),
  new WordPyramidSolution([
    ["cho", "Harry's first kiss"],
    ["echo", "Kind of chamber"],
    ["theoc", "Fox series that took place in Newport Beach"],
    ["cheeto", "Snack puff"],
    ["thecore", "Rotten to ___"],
    ["coherent", "Able to be understood"],
  ]),
  new WordPyramidSolution([
    ["ego", "Narcissist's issue"],
    ["egot", "American art award acronym"],
    ["begot", "Sired, biblically"],
    ["bigtoe", "Largest 'little piggy'"],
    ["bigoted", "Intolerant"],
    ["goindebt", "Spend more than you have"],
  ]),
  new WordPyramidSolution([
    ["bar", "Milky Way, e.g."],
    ["aber", "But, in Berlin"],
    ["embar", "Put in a cell"],
    ["umbrae", "Shadows"],
    ["barmenu", "Happy hour handout"],
    ["menubars", "Places to find top-level commands?"],
  ]),
  new WordPyramidSolution([
    ["tab", "Key that indents"],
    ["beta", "A ray"],
    ["beast", "Love of Beauty"],
    ["absent", "Truant's status"],
    ["banters", "Teases good-naturedly"],
    ["tendsbar", "Does some mixology"],
  ]),
  new WordPyramidSolution([
    ["gin", "Card game or tonic complement"],
    ["grin", "Expose incisors, perhaps"],
    ["inorg", "Neither plant nor animal: Abbr."],
    ["groins", "Athletes may pull them"],
    ["sporing", "Germinating"],
    ["sporting", "Dog show group"],
  ]),
  new WordPyramidSolution([
    ["rsa", "Cape Town's nation: Abbr."],
    ["ears", "Snakes lack them"],
    ["asred", "____ as a beet"],
    ["sacred", "Worthy of holy veneration"],
    ["usedcar", "Vehicle that's been around the block"],
    ["accursed", "Ill-fated"],
  ]),
  new WordPyramidSolution([
    ["tat", "Bit of needlework"],
    ["tate", "Gallery near the Thames"],
    ["testa", "\"... if you want to __ man's character, give him power\": Lincoln"],
    ["getsat", "Intimates"],
    ["gestate", "Carry before birth"],
    ["getstale", "Lose freshness"],
  ]),
  new WordPyramidSolution([
    ["sip", "Chug's opposite"],
    ["pies", "Papa John's orders"],
    ["sepia", "Warm, antique filter"],
    ["elpais", "Spanish newspaper whose name means \"The Country\""],
    ["special", "Something not on the menu"],
    ["replicas", "Facsimiles"],
  ]),
  new WordPyramidSolution([
    ["eco", "Prefix with tourism"],
    ["cone", "Edible Ice cream cup"],
    ["ocean", "Heist film series surname"],
    ["canoer", "One in a tippy boat"],
    ["corneal", "Pertaining to an eye part"],
    ["falconer", "Trainer of goshawks, likely"],
  ]),
  new WordPyramidSolution([
    ["ali", "Boxer dubbed \"The Greatest\""],
    ["sail", "Jib or mizzen"],
    ["aisle", "Theater walkway"],
    ["aslice", "Go for ___ (get some pizza)"],
    ["malices", "Feelings of spite"],
    ["climaxes", "High points"],
  ]),
  new WordPyramidSolution([
    ["ole", "A cheer in Pamplona"],
    ["sole", "Bottom of a sneaker, which might be stored in answer #4"],
    ["stole", "A type of shawl, which might be stored in answer #4"],
    ["closet", "Place to store answer #3 and answer #5"],
    ["clothes", "Things often stored in answer #4"],
    ["blotches", "Reasons to bleach your answer #5"],
  ]),
  new WordPyramidSolution([
    ["pop", "Kind of art or tart"],
    ["opps", "Near and far, e.g.: Abbr."],
    ["poops", "Tires (out)"],
    ["postop", "Place to recover, for short"],
    ["toppros", "PGA leaders, say"],
    ["croptops", "Midriff-revealing shirts"],
  ]),
  new WordPyramidSolution([
    ["mac", "Cheese's pasta partner"],
    ["scam", "Fraudulent scheme"],
    ["comas", "States of prolonged unconsciousness"],
    ["mosaic", "Pattern of small tiles"],
    ["masonic", "Like Shriners or Templars"],
    ["monastic", "Reclusive and austere"],
  ]),
  new WordPyramidSolution([
    ["ire", "Angry feeling"],
    ["tire", "Rubber that meets the road"],
    ["irate", "More than angry"],
    ["retail", "Wholesale alternative"],
    ["article", "'A' or 'an'"],
    ["clarinet", "Saxophone's kin"],
  ]),
  new WordPyramidSolution([
    ["rot", "Zombie affliction"],
    ["riot", "Prison disruption"],
    ["ratio", "Batting average or body mass index"],
    ["airout", "Open the windows"],
    ["rainout", "Weather-related cancellation"],
    ["minotaur", "Fearsome figure in Greek myth"],
  ]),
  new WordPyramidSolution([
    ["set", "Volleyball scoring unit"],
    ["east", "To the right, on a map"],
    ["dates", "Prenuptial events"],
    ["sedate", "Give Xanax, say"],
    ["etrades", "Online stock transactions"],
    ["steadier", "Less flighty"],
  ]),
  new WordPyramidSolution([
    ["ail", "Suffer some"],
    ["sail", "Windjammer's wind catcher"],
    ["alias", "Spy's name, perhaps"],
    ["salami", "Charcuterie choice"],
    ["animals", "Zoo collection"],
    ["somalian", "Mogadishu resident"],
  ]),
  new WordPyramidSolution([
    ["lit", "On, like a lamp"],
    ["tilt", "Pinball machine no-no"],
    ["stilt", "High-stepping aid"],
    ["titles", "People on thrones might have them"],
    ["toilets", "Thrones, so to speak"],
    ["letsinto", "Admits"],
  ]),
  new WordPyramidSolution([
    ["rag", "Old washcloth, say"],
    ["crag", "Projecting rock"],
    ["cigar", "Smoke producer"],
    ["arcing", "Like the path of a high basketball shot"],
    ["organic", "All natural"],
    ["caroling", "Yuletide activity"],
  ]),
  new WordPyramidSolution([
    ["sup", "Bro hello"],
    ["plus", "Addition symbol"],
    ["pulse", "Throb or heartbeat"],
    ["supple", "Pliant"],
    ["pilesup", "Makes a stack"],
    ["supplier", "Company's source of goods"],
  ]),
  new WordPyramidSolution([
    ["tin", "Soft metal in cans"],
    ["lint", "Dryer material"],
    ["glint", "Inkling"],
    ["tiling", "Tessellation"],
    ["tailing", "Behind, in a way"],
    ["litigant", "Plaintiff"],
  ]),
  new WordPyramidSolution([
    ["net", "Goalie's spot"],
    ["sent", "Outbox folder"],
    ["tones", "Salon selections"],
    ["stolen", "Like some bases and kisses"],
    ["notales", "What dead men tell"],
    ["labnotes", "Jottings made in biology class"],
  ]),
  new WordPyramidSolution([
    ["red", "Kind of salmon or herring"],
    ["herd", "Mass of cows"],
    ["horde", "Mass of humanity"],
    ["rehoed", "Got the soil ready again"],
    ["othered", "Relegated to outsider status, in modern lingo"],
    ["adhereto", "Go by, as rules"],
  ]),
  new WordPyramidSolution([
    ["ose", "Suffix for sugars"],
    ["rose", "National flower of the United States"],
    ["loser", "Juvenile put-down"],
    ["sorely", "One way to be missed"],
    ["soberly", "Best way to drive"],
    ["somberly", "In a gloomy way"],
  ]),
  new WordPyramidSolution([
    ["are", "'Roses ___ red'"],
    ["real", "Not fake or phony"],
    ["abler", "Not as inept"],
    ["labret", "Lip ornament"],
    ["ratable", "Like a movie, to a critic"],
    ["albertan", "Neighbor of a Montanan"],
  ]),
  new WordPyramidSolution([
    ["pig", "2019 zodiac animal"],
    ["ping", "Aluminum bat sound"],
    ["aping", "Mocker's ploy"],
    ["paying", "Treating"],
    ["spaying", "Fixing, in a way"],
    ["splaying", "Spreading out"],
  ]),
  new WordPyramidSolution([
    ["san", "___ Salvador, El Salvador"],
    ["ants", "Picnic plaguers"],
    ["saint", "Drew Brees, notably"],
    ["seitan", "Gluten-based alternative to tofu"],
    ["intakes", "Consumed amounts"],
    ["itstaken", "Reply to a seat seeker"],
  ]),
  new WordPyramidSolution([
    ["ord", "Windy City hub letters"],
    ["oder", "German boundary river and conjunction"],
    ["gored", "Ran through"],
    ["groped", "Searched blindly"],
    ["grouped", "Sorted homogeneously"],
    ["roundpeg", "Dowel, e.g."],
  ]),
  new WordPyramidSolution([
    ["lee", "Gymnastics champion Suni"],
    ["alee", "On the quiet side, at sea"],
    ["neela", "Bless with oil"],
    ["baleen", "Whalebone"],
    ["tenable", "Like a well-grounded argument"],
    ["rentable", "Like moving vans and hotel rooms"],
  ]),
  new WordPyramidSolution([
    ["las", "___ Vegas"],
    ["loas", "Haitian voodoo gods"],
    ["salon", "Where to get highlights"],
    ["alison", "Brie of \"Community\""],
    ["allison", "Actress Janney who won an Oscar for \"I, Tonya\""],
    ["loislane", "Noted \"Daily Planet\" journalist"],
  ]),
  new WordPyramidSolution([
    ["ear", "Cob + kernels"],
    ["near", "Around the corner, say"],
    ["reran", "Stood for a second term"],
    ["errand", "Fetching task"],
    ["adorner", "Prettifier"],
    ["narrowed", "Became one lane, say"],
  ]),
  new WordPyramidSolution([
    ["tan", "Sunbather's acquistion"],
    ["anti", "Opposition position"],
    ["saint", "Joan of Arc, e.g."],
    ["sanity", "Clearmindedness"],
    ["nastily", "Unpleasantly"],
    ["laysiton", "Exaggerates"],
  ]),
  new WordPyramidSolution([
    ["nog", "Eggy libation"],
    ["snog", "Cuddle and kiss in Hyde Park"],
    ["agons", "Greek contests"],
    ["genoas", "Some salamis"],
    ["nosegay", "Corsage cousin"],
    ["goeasyon", "Show leniency toward"],
  ]),
  new WordPyramidSolution([
    ["too", "___ close for comfort"],
    ["hoot", "Owl's cry"],
    ["shoot", "Filming session"],
    ["soothe", "Placate"],
    ["hotones", "Hot sauce with an accompanying YouTube channel"],
    ["smoothen", "Sand, maybe"],
  ]),
  new WordPyramidSolution([
    ["sit", "Use a bench"],
    ["mist", "Waterfall spray"],
    ["mints", "Freebies on pillows"],
    ["mantis", "Praying preyer?"],
    ["mtsinai", "Site of \"Moses' cave\""],
    ["martinis", "007 prefers them \"shaken, not stirred\""],
  ]),
  new WordPyramidSolution([
    ["lot", "Word after parking or auction"],
    ["slot", "Pay phone feature"],
    ["molts", "Casts off skin"],
    ["almost", "Nearly"],
    ["maltose", "Corn syrup component"],
    ["loamiest", "Like the best topsoil"],
  ]),
  new WordPyramidSolution([
    ["sir", "Chivalrous address"],
    ["rise", "\"Star Wars: The ___ of Skywalker\""],
    ["raise", "Workplace incentive"],
    ["satire", "\"The Daily Show\" device"],
    ["raciest", "Most provocative"],
    ["craziest", "Most shocking"],
  ]),
  new WordPyramidSolution([
    ["rus", "Medvedev's country: abbr."],
    ["sure", "\"I'd be glad to\""],
    ["surge", "Sudden large increase"],
    ["grouse", "Game bird"],
    ["morgues", "DOA sites"],
    ["gruesome", "Grisly"],
  ]),
  new WordPyramidSolution([
    ["hat", "Dr. Seuss's \"The Cat in the ___\""],
    ["oath", "Witness-stand pledge"],
    ["torah", "Its final word is \"Israel\""],
    ["harlot", "Tart"],
    ["rathole", "Lowly digs"],
    ["plethora", "Extreme excess"],
  ]),
  new WordPyramidSolution([
    ["rim", "Customizable wheel part"],
    ["mire", "Deep, soft mud"],
    ["merit", "Kind of badge for a Boy Scout"],
    ["retime", "Clock again"],
    ["meatier", "More substantial"],
    ["emirates", "Jurisdictions in Arabia"],
  ]),
  new WordPyramidSolution([
    ["oar", "Boat implement"],
    ["soar", "Emulate a frigatebird"],
    ["arson", "Fire chief's suspicion"],
    ["reason", "Logician's forte"],
    ["wearson", "Bores"],
    ["warzones", "Places on travel advisory lists"],
  ]),
  new WordPyramidSolution([
    ["lea", "Where ewes roam"],
    ["teal", "Crystal ___ (shade akin to celadon blue)"],
    ["stale", "Past the expiration date"],
    ["deltas", "Mississippi and Nile, for two"],
    ["dilates", "Widens, as pupils"],
    ["ifeltsad", "\"That really depressed me\""],
  ]),
  new WordPyramidSolution([
    ["ete", "Août season"],
    ["atee", "To ___"],
    ["eater", "Smorgasbord fan"],
    ["create", "Forge"],
    ["reacted", "Flinched or blinked"],
    ["decorate", "Embellish"],
  ]),
  new WordPyramidSolution([
    ["ers", "EMTs' destinations"],
    ["sore", "In need of a masseuse"],
    ["roses", "They're often bought in twelves"],
    ["snores", "Disturbs the peace, in a way"],
    ["engross", "Occupy completely"],
    ["congress", "Capitol collective"],
  ]),
  new WordPyramidSolution([
    ["nod", "Drift off to sleep"],
    ["dons", "Puts on, as clothes"],
    ["sound", "Puget or Pamlico"],
    ["founds", "Establishes"],
    ["fondues", "Melted-cheese dishes"],
    ["founders", "Original members"],
  ]),
  new WordPyramidSolution([
    ["sie", "You, in Dresden"],
    ["sire", "Horse's dad"],
    ["riser", "Alarm heeder"],
    ["sirree", "'Yes, --!'"],
    ["resider", "Tenant"],
    ["reraised", "Made an aggressive play at the poker table"],
  ]),
  new WordPyramidSolution([
    ["rag", "One who has it is ready to clean"],
    ["rage", "One who has it is ready to fight"],
    ["eager", "ready"],
    ["geared", "____ up (ready)"],
    ["greased", "Like a cookie sheet ready to bake"],
    ["disagree", "Not see eye to eye"],
  ]),
  new WordPyramidSolution([
    ["rat", "Chinese zodiac sign between pig and ox"],
    ["cart", "Something to take shopping"],
    ["track", "Racecourse"],
    ["racket", "Great noise"],
    ["tackier", "More dowdy"],
    ["tieracks", "Hanging organizers"],
  ]),
  new WordPyramidSolution([
    ["hat", "This might go over your head"],
    ["chat", "Chit follower"],
    ["teach", "Drill effectively"],
    ["cheats", "Doesn't play by the rules"],
    ["thecast", "Movie credits caption"],
    ["chastest", "Most virtuous"],
  ]),
  new WordPyramidSolution([
    ["lit", "Activated, as a fire"],
    ["slit", "Cut, as with a letter opener"],
    ["tiles", "Bathroom squares"],
    ["enlist", "Respond to an \"I Want You\" poster"],
    ["singlet", "Marathoner's shirt"],
    ["sterling", "Like much flatware"],
  ]),
  new WordPyramidSolution([
    ["rut", "Dirt road groove"],
    ["rust", "Metal corrosion"],
    ["strut", "Trot pompously"],
    ["utters", "Puts into words"],
    ["stature", "Upright height"],
    ["outstare", "Win a \"no blinking\" contest"],
  ]),
  new WordPyramidSolution([
    ["ade", "Ending for Power or Gator, in drink brand names"],
    ["date", "Fourth of July, e.g."],
    ["hated", "Like a nemesis, say"],
    ["heated", "Like some altercations"],
    ["thedead", "Jerry Garcia's band, familiarly"],
    ["headedto", "Left for"],
  ]),
  new WordPyramidSolution([
    ["red", "Symbol for stopping"],
    ["reed", "Clarinet's vibrator"],
    ["greed", "Gollum is possessed by it"],
    ["agreed", "Gave consent"],
    ["greased", "Lubricated"],
    ["degrades", "Badmouths"],
  ]),
  new WordPyramidSolution([
    ["ted", "___ Lasso, AFC Richmond coach"],
    ["tude", "Bit of defiance, in slang"],
    ["usted", "\"¿Cómo está ___?\""],
    ["lusted", "Had erotic cravings"],
    ["rustled", "Stirred slightly, as leaves"],
    ["lustered", "Gave a glossy finish"],
  ]),
  new WordPyramidSolution([
    ["won", "Got"],
    ["wong", "Mariners second baseman Kolten"],
    ["owing", "With bills due"],
    ["towing", "Service-station service"],
    ["hotwing", "Spicy finger food item"],
    ["nightowl", "Early bird's opposite?"],
  ]),
  new WordPyramidSolution([
    ["dan", "Rank in judo"],
    ["sand", "Hourglass sight"],
    ["sedan", "Biggish car"],
    ["ascend", "Get high, like Jesus"],
    ["incased", "Boxed"],
    ["diocesan", "Related to a Catholic bishop's purview"],
  ]),
  new WordPyramidSolution([
    ["rei", "Outdoor apparel chain"],
    ["ride", "Theme park feature"],
    ["diner", "Eatery with booths"],
    ["hinder", "Cause delays, maybe"],
    ["handier", "More capable at fixing things"],
    ["ironhead", "Nickname for racer Dale Earnhardt Sr., so called because of his stubbornness"],
  ]),
  new WordPyramidSolution([
    ["bar", "Ingot, for instance"],
    ["bart", "Underground transportation system in San Francisco, CA"],
    ["rabat", "World capital formerly a pirate stronghold"],
    ["oatbar", "Granola treat"],
    ["aerobat", "Performing pilot"],
    ["afrobeat", "Jazz/funk fusion genre"],
  ]),
  new WordPyramidSolution([
    ["ote", "Coy ending?"],
    ["tore", "Gave way, as cloth"],
    ["tenor", "An opera voice"],
    ["stoner", "High-minded person?"],
    ["cornets", "Trumpets' cousins"],
    ["coronets", "Royal headpieces"],
  ]),
  new WordPyramidSolution([
    ["gal", "Whom a straight guy do-si-dos with"],
    ["slag", "Smelting residue"],
    ["glass", "\"___ Onion,\" Beatles song"],
    ["slangs", "Jargons"],
    ["slogans", "Politicians often repeat them"],
    ["lassoing", "Roping (cattle)"],
  ]),
  new WordPyramidSolution([
    ["gal", "Whom a (straight) guy do-si-dos with"],
    ["slag", "Smelting residue"],
    ["glass", "\"___ Onion,\" Beatles song"],
    ["slangs", "Jargons"],
    ["slogans", "Politicians often repeat them"],
    ["lassoing", "Roping (cattle)"],
  ]),
  new WordPyramidSolution([
    ["spa", "Recuperative spot"],
    ["saps", "Drains one's energy"],
    ["apses", "Notre Dame has several of these"],
    ["sepias", "Brownish photo hues"],
    ["pasties", "Semicircular British pies"],
    ["soapiest", "Maximally sudsy"],
  ]),
  new WordPyramidSolution([
    ["sud", "The south of France?"],
    ["stud", "Earlobe jewelry"],
    ["duets", "Compositions for two"],
    ["rudest", "Least courteous"],
    ["dustier", "Less recently cleaned"],
    ["usedtire", "One (or four) might need a replacement at the autoshop"],
  ]),
  new WordPyramidSolution([
    ["asl", "Silent letters?"],
    ["elsa", "Princess in \"Frozen\""],
    ["tales", "Outlandish stories"],
    ["lattes", "Drinks with foamy tops"],
    ["stately", "Dignified and impressive"],
    ["stealthy", "Like a cat burglar"],
  ]),
  new WordPyramidSolution([
    ["sit", "Rest on a meditation cushion"],
    ["suit", "One of a deck's four"],
    ["units", "Landlord's concerns"],
    ["unites", "Makes one out of two"],
    ["aunties", "Mother's sisters, fondly"],
    ["intheusa", "\"I was born ___\" (Springsteen)"],
  ]),
  new WordPyramidSolution([
    ["rus", "Tsar's land: Abbr."],
    ["sure", "Completely certain"],
    ["nurse", "ICU worker"],
    ["unrest", "Riots and such"],
    ["entrust", "Hand over for safekeeping"],
    ["rentsout", "Offers to tenants"],
  ]),
  new WordPyramidSolution([
    ["cad", "Unchivalrous man"],
    ["aced", "Served perfectly on a court"],
    ["laced", "Tied up, as skates"],
    ["colead", "One of two main roles in a movie"],
    ["caroled", "Sang joyously"],
    ["colander", "Kitchen sieve"],
  ]),
  new WordPyramidSolution([
    ["dos", "10% of veinte"],
    ["doso", "\"I ___ appreciate ...\""],
    ["stood", "Prepared for the anthem, say"],
    ["godots", "Game engine and mysterious titular character of a Samuel Beckett play"],
    ["lostdog", "Heading on a neighborhood poster"],
    ["oldgoats", "Geezers"],
  ]),
  new WordPyramidSolution([
    ["red", "Color of a black widow's hourglass"],
    ["deer", "Faline or Bambi"],
    ["elder", "Someone with seniority"],
    ["redeal", "New hand distribution"],
    ["leadore", "Slag furnace input"],
    ["reloaded", "Like guns and dump trucks, over and over"],
  ]),
  new WordPyramidSolution([
    ["dos", "Two, to Juan"],
    ["dots", "They're raised in Braille"],
    ["toads", "Warty pond denizens"],
    ["doaset", "Perform some songs"],
    ["oatseed", "Grain-crop item"],
    ["desolate", "Lonely"],
  ]),
  new WordPyramidSolution([
    ["toe", "Tip of a sock"],
    ["oeta", "Site of Hercules' death"],
    ["poeta", "Gabriele D'Annunzio, e.g."],
    ["potage", "French onion soup, e.g."],
    ["agespot", "Laser target, perhaps"],
    ["sportage", "Kia SUV"],
  ]),
  new WordPyramidSolution([
    ["hoi", "\"Polloi\" lead-in"],
    ["holi", "Hindu festival"],
    ["shilo", "Hawaiian city and bay"],
    ["helios", "Chariot-driving Greek god"],
    ["hollies", "Ornamental shrubs"],
    ["shelloil", "Its logo is based on a scallop"],
  ]),
  new WordPyramidSolution([
    ["est", "Winter clock setting in Vt."],
    ["este", "Cádiz compass point"],
    ["tease", "Goad or vex"],
    ["atease", "Calm"],
    ["asabeet", "As red ___"],
    ["ratebase", "Mag's guarantee to advertisers"],
  ]),
  new WordPyramidSolution([
    ["rod", "Divining implement"],
    ["trod", "Trampled, with \"on\""],
    ["redot", "Put a period back"],
    ["rotted", "Broke down"],
    ["tradeto", "Exchange with, in sports"],
    ["treetoad", "Sticky-toed hopper"],
  ]),
  new WordPyramidSolution([
    ["lap", "Dog's drink, or resting spot"],
    ["slap", "High-five, basically"],
    ["lapis", "Lazuli's mate"],
    ["palish", "Unhealthily light"],
    ["planish", "Hammer metal"],
    ["plainish", "Ordinary looking"],
  ]),
  new WordPyramidSolution([
    ["ess", "Suffix for \"baron\" or \"shepherd\""],
    ["uses", "Makes a pawn of"],
    ["russe", "From \"Moscou\""],
    ["nurses", "Fosters"],
    ["redsuns", "Scenes at dusk"],
    ["resounds", "Fills the air"],
  ]),
  new WordPyramidSolution([
    ["syr", "Leb. ally"],
    ["drys", "Prohibition proponents"],
    ["isdry", "\"When the well ---, we know the worth of water\""],
    ["diyers", "Handy sorts"],
    ["semidry", "Like some cabernet sauvignon"],
    ["midyears", "Some school exams"],
  ]),
  new WordPyramidSolution([
    ["tde", "Kendrick Lamar label, abbr."],
    ["deet", "Insect repellent ingredient"],
    ["dente", "Al _____ (way to cook pasta)"],
    ["needto", "'No -- apologize'"],
    ["denotes", "Stands for"],
    ["endnotes", "David Foster Wallace trademark"],
  ]),
  new WordPyramidSolution([
    ["set", "Studio filming location"],
    ["vets", "Docs for dachshunds"],
    ["viets", "Hanoi people"],
    ["stevia", "Sugar substitute"],
    ["aviates", "Goes barnstorming, say"],
    ["sanative", "Having curing powers"],
  ]),
  new WordPyramidSolution([
    ["ant", "Resident of a formicary"],
    ["nota", "___ dry eye in the house"],
    ["baton", "Relay link"],
    ["obtain", "Acquire"],
    ["boating", "Enjoying the ocean"],
    ["bloating", "Possible side effect in TV drug ads"],
  ]),
  new WordPyramidSolution([
    ["wad", "Bit of gum"],
    ["dawn", "Start of the day"],
    ["waned", "Diminished over time, as the moon"],
    ["weaned", "Independent, as young"],
    ["wakened", "Arose"],
    ["weakened", "Watered down"],
  ]),
  new WordPyramidSolution([
    ["ser", "Church msg."],
    ["rose", "February flower"],
    ["robes", "Ring attire"],
    ["booers", "Lauders' opposites"],
    ["reboots", "Starts up after a crash"],
    ["octobers", "Tailgate-picnic months"],
  ]),
  new WordPyramidSolution([
    ["ode", "\"__ to Joy\""],
    ["dose", "Portion of medicine"],
    ["posed", "Got shot in a studio?"],
    ["pseudo", "Prefix meaning \"false\""],
    ["spouted", "Spewed forth"],
    ["postlude", "Concluding movement"],
  ]),
  new WordPyramidSolution([
    ["rag", "Fast-moving piano piece"],
    ["rage", "Blow a fuse"],
    ["gaper", "Awed chap"],
    ["repage", "Beep again"],
    ["pergame", "How many sports stats are measured"],
    ["pregamed", "Had drinks before going out, informally"],
  ]),
  new WordPyramidSolution([
    ["tat", "Body art, for short"],
    ["tate", "Modern museum in London"],
    ["tetea", "___-tête"],
    ["eateth", "Consumes, biblically"],
    ["thebeat", "What conductors keep"],
    ["thebeast", "Fairy tale title character"],
  ]),
  new WordPyramidSolution([
    ["die", "Vegas roller"],
    ["side", "Baked beans or mac salad"],
    ["decis", "Prefixes for tenth"],
    ["sliced", "Didn't drive well"],
    ["sluiced", "Washed with water."],
    ["includes", "Takes in"],
  ]),
  new WordPyramidSolution([
    ["ref", "Short official?"],
    ["nerf", "Brand of foam darts"],
    ["efron", "Zac of 'Hairspray'"],
    ["confer", "Discuss"],
    ["enforce", "Implement, in a way"],
    ["cornbeef", "Cabbage's partner"],
  ]),
  new WordPyramidSolution([
    ["oat", "Muesli morsel"],
    ["taro", "Tropical tuber"],
    ["ratio", "Math comparison"],
    ["taroil", "Boat-deck application"],
    ["ontrial", "Subject to being found satisfactory"],
    ["oriental", "Monopoly avenue next to Chance"],
  ]),
  new WordPyramidSolution([
    ["tab", "Keyboard word"],
    ["stab", "Sharp pang"],
    ["boast", "Be braggadocious"],
    ["bloats", "Swells"],
    ["ballots", "Voters' slates"],
    ["tollbars", "Bridge gates"],
  ]),
  new WordPyramidSolution([
    ["ton", "Unit of capacity for a bridge"],
    ["iton", "Lay ___"],
    ["tobin", "[Answer 6] in Economics: 1981"],
    ["benito", "Adolf's ally"],
    ["boniest", "Least fleshy"],
    ["nobelist", "Sartre, Singer or Solzhenitsyn"],
  ]),
  new WordPyramidSolution([
    ["are", "\"___ You Smarter Than a 5th Grader?\""],
    ["care", "Watchful attention"],
    ["scare", "Kind of tactic"],
    ["cranes", "Origami birds"],
    ["canters", "Easy gallops"],
    ["ancestry", "Family tree subject"],
  ]),
  new WordPyramidSolution([
    ["sin", "No-no"],
    ["sign", "Endorse a check"],
    ["singe", "Burn superficially"],
    ["binges", "Indulges in too much Netflix, say"],
    ["besting", "Outstripping"],
    ["beesting", "Flower garden hazard"],
  ]),
  new WordPyramidSolution([
    ["lad", "Young chap"],
    ["lead", "Alchemy material"],
    ["medal", "Olympian's hardware"],
    ["damsel", "Interest of a knight in shining armor"],
    ["modelas", "Vintage Fords"],
    ["dolmades", "Stuffed vine leaves"],
  ]),
  new WordPyramidSolution([
    ["ale", "Quaff in Middle-earth"],
    ["sale", "Ad topper, often"],
    ["laser", "Myopia corrector"],
    ["israel", "Mediterranean country"],
    ["belairs", "Classic Chevrolets"],
    ["raisable", "Like ruckuses or roadster roofs"],
  ]),
  new WordPyramidSolution([
    ["hit", "Very successful show"],
    ["this", "Partner of that"],
    ["heist", "Bonnie and Clyde caper"],
    ["ethics", "Philosophical study of moral values"],
    ["techies", "Online helpers"],
    ["cheezits", "Orange square snacks"],
  ]),
  new WordPyramidSolution([
    ["car", "The Batmobile, notably"],
    ["race", "What sailors breathe"],
    ["scare", "Fill with fear"],
    ["caesar", "Salad with romaine lettuce"],
    ["carsale", "Event where you might get a ride"],
    ["coralsea", "Home of the Great Barrier Reef"],
  ]),
  new WordPyramidSolution([
    ["sot", "Booze abuser"],
    ["hots", "Red ___ (cinnamon candies)"],
    ["sloth", "Lugubrious mammal"],
    ["hostel", "Cheap place to stay"],
    ["loathes", "Can't stomach"],
    ["hotleads", "Detective's promising clues"],
  ]),
  new WordPyramidSolution([
    ["nit", "Little criticism"],
    ["unit", "Platoon"],
    ["outin", "___ the open (uncovered)"],
    ["toruin", "Bring ___ (undo)"],
    ["ringout", "Resound"],
    ["rousting", "Description of an impassioned speech, perhaps"],
  ]),
  new WordPyramidSolution([
    ["rat", "Sewer scurrier"],
    ["star", "One of 50 on the American flag"],
    ["rates", "$25/hour and the like"],
    ["assert", "\"Any ___?\" (\"Who's in?\")"],
    ["satires", "Jonathan Swift specialties"],
    ["asterisk", "Footnote figure"],
  ]),
  new WordPyramidSolution([
    ["per", "Capita go-with"],
    ["perm", "Curly hairstyle"],
    ["sperm", "Male gamete"],
    ["primes", "Indivisible numbers"],
    ["umpires", "Baseball officials"],
    ["subprime", "Kind of mortgage"],
  ]),
  new WordPyramidSolution([
    ["ein", "A, to Beethoven"],
    ["mine", "Silver or salt source"],
    ["anime", "Japanese cartoon art"],
    ["inmate", "One finishing a sentence"],
    ["ailment", "What's got you down?"],
    ["laminate", "Compress into a thin plate"],
  ]),
  new WordPyramidSolution([
    ["ron", "Harry and Hermione's buddy"],
    ["corn", "Iowa's largest export"],
    ["recon", "Drone's work, maybe"],
    ["coiner", "He really makes money"],
    ["conifer", "Fir or pine"],
    ["incareof", "To be handled by"],
  ]),
  new WordPyramidSolution([
    ["and", "Tea ___ crumpets"],
    ["sand", "Kind of bag, bank or bar"],
    ["darns", "Mends a sock"],
    ["nadirs", "Bottommost points"],
    ["radians", "Units of angular measurement"],
    ["spaniard", "Picasso or El Cid"],
  ]),
  new WordPyramidSolution([
    ["nil", "Zero, in soccer box scores"],
    ["line", "Theme park annoyance"],
    ["alien", "Non-national"],
    ["linear", "Like the equation x = 2y"],
    ["lankier", "Taller and leaner"],
    ["kinglear", "\"O! let me not be mad\" speaker"],
  ]),
  new WordPyramidSolution([
    ["ail", "Be under par, healthwise"],
    ["nail", "It's painted by a manicurist"],
    ["align", "Make straight"],
    ["malign", "Drag through the mud"],
    ["flaming", "Being severely critical on-line"],
    ["flamingo", "Lawn ornament"],
  ]),
  new WordPyramidSolution([
    ["lei", "Hawaiian flower necklace"],
    ["isle", "It may be uncharted"],
    ["slice", "Bad golf swing"],
    ["chiles", "Habaneros and jalapenos"],
    ["helices", "DNA shapes"],
    ["vehicles", "Planes, trains, and automobiles"],
  ]),
  new WordPyramidSolution([
    ["bet", "Money on the game"],
    ["best", "Gold-medal caliber"],
    ["besot", "Make smitten"],
    ["bestow", "Grant, as an award"],
    ["basetwo", "Computer code system"],
    ["sweatbox", "Prisoner's holding cell"],
  ]),
  new WordPyramidSolution([
    ["sun", "Arizona State's ___ Devils"],
    ["runs", "Scoreboard postings"],
    ["ruins", "Pompeii attraction"],
    ["runins", "Clashes, as with the law"],
    ["turnsin", "Submits"],
    ["runniest", "The nose most in need of tissues"],
  ]),
  new WordPyramidSolution([
    ["sat", "Plopped down"],
    ["tabs", "Bar amounts"],
    ["betas", "Test versions of apps"],
    ["breast", "Chicken part"],
    ["baiters", "Enticers"],
    ["banister", "It might be held on a flight"],
  ]),
  new WordPyramidSolution([
    ["sud", "Direction opposite nord"],
    ["duds", "Bombs without bangs"],
    ["dudes", "Vacationers on a ranch"],
    ["udders", "Milking machine attachments"],
    ["shudder", "Quake"],
    ["hundreds", "Franklin bills"],
  ]),
  new WordPyramidSolution([
    ["end", "\"The ____\", often closing a Fairy Tale"],
    ["mend", "Fix, as clothes"],
    ["amend", "Fix, as a law"],
    ["demean", "Lower in status"],
    ["renamed", "Changed the title"],
    ["remained", "Stayed"],
  ]),
  new WordPyramidSolution([
    ["leo", "Horoscope listing"],
    ["role", "Acting opportunity"],
    ["older", "Something you get on your birthday"],
    ["retold", "Like urban legends, again and again"],
    ["deltoro", "\"The Shape of Water\" director, Guillermo"],
    ["towelrod", "Bathroom fixture"],
  ]),
  new WordPyramidSolution([
    ["ole", "Flamenco exclamation"],
    ["love", "Marriage partner"],
    ["solve", "Fill this Pyramini"],
    ["olives", "Fruits used for virgin product"],
    ["violets", "Napolean's flowers"],
    ["novelist", "Published storyteller"],
  ]),
  new WordPyramidSolution([
    ["san", "Title for Jose or Diego?"],
    ["sane", "Not cuckoo"],
    ["sedan", "Many a family car"],
    ["sander", "Wood-smoothing machine"],
    ["hardens", "Solidifies, like concrete"],
    ["handrest", "Ergonomic keyboard feature"],
  ]),
  new WordPyramidSolution([
    ["pre", "Historic preceder"],
    ["rope", "Part of a hangman drawing"],
    ["trope", "Movie cliche"],
    ["petrol", "Liverpool fuel"],
    ["plotter", "Person making a graph, say"],
    ["teleport", "Use a futuristic mode of transit"],
  ]),
  new WordPyramidSolution([
    ["sam", "Green eggs and ham pusher"],
    ["slam", "Shut a door with a bang"],
    ["calms", "Becomes less angry"],
    ["camels", "Caravan creatures"],
    ["malices", "Feelings of spite"],
    ["reclaims", "Restores wasteland"],
  ]),
  new WordPyramidSolution([
    ["alf", "E.T. of TV"],
    ["fail", "Flunk out"],
    ["afile", "Have ___ on (monitor officially)"],
    ["ifeela", "\"___ Song Comin' On\""],
    ["arelief", "'What --!' ('Whew!')"],
    ["reallife", "Actuality"],
  ]),
  new WordPyramidSolution([
    ["stn", "Place to buy tickets: Abbr."],
    ["tnts", "Great levelers?"],
    ["notts", "English county that sounds hard to untie"],
    ["itsnot", "\"___ easy being green\", song by the Muppets"],
    ["tinpots", "Inferior cookware"],
    ["stinkpot", "Despicable person"],
  ]),
  new WordPyramidSolution([
    ["est", "N.Y. minutes, in the winter"],
    ["sate", "Fill beyond full"],
    ["seats", "Sofas and chairs"],
    ["hastes", "Speeds, to poets"],
    ["ashiest", "Most pallid-looking"],
    ["shakiest", "Least stable"],
  ]),
  new WordPyramidSolution([
    ["rid", "\"__ yourselves of all malice\": 1 Peter"],
    ["dirt", "Ground cover"],
    ["tired", "Sick companion?"],
    ["tirade", "Long rant"],
    ["adroite", "To the right, in Paris"],
    ["boatride", "Trip on a ship"],
  ]),
  new WordPyramidSolution([
    ["led", "Supervised"],
    ["deal", "Salesman's goal"],
    ["baled", "Packed up, in a way"],
    ["leadby", "___ example, something a good coach or captain might do"],
    ["bradley", "Washington Wizards all-time leading scorer, Beal"],
    ["benadryl", "Antihistamine used in some hallucinogenic drugs"],
  ]),
  new WordPyramidSolution([
    ["sir", "Camelot title"],
    ["rise", "\"And Still I _____\" (Maya Angelou poem)"],
    ["rinse", "Hygienist's instruction"],
    ["miners", "Team of the University of Texas at El Paso"],
    ["armisen", "Obama's impersonator on \"SNL\""],
    ["mariners", "Team with the best single-season record in MLB history"],
  ]),
  new WordPyramidSolution([
    ["oar", "Crew-team tool"],
    ["soar", "What spirits may do"],
    ["solar", "Pertaining to the sun"],
    ["molars", "Elephant's dental dozen"],
    ["morales", "Animated spiderman surname"],
    ["imaloser", "Dour declaration"],
  ]),
  new WordPyramidSolution([
    ["nip", "Frost in the air"],
    ["spin", "Do a DJ set, informally"],
    ["spine", "Part of a book cover"],
    ["instep", "Shoe sole curve"],
    ["imspent", "\"That really wore me out\""],
    ["shipment", "Package that's en route"],
  ]),
  new WordPyramidSolution([
    ["lac", "So. Cal. NBA team"],
    ["cola", "Drink with lots of bubbles"],
    ["coral", "Reef growth"],
    ["oracle", "Magic 8 Ball"],
    ["coalers", "Fuel supply ships"],
    ["calories", "Diet-book listings"],
  ]),
  new WordPyramidSolution([
    ["ays", "Captains' cries"],
    ["stay", "Don't budge"],
    ["tyras", "Supermodel Banks and namesakes"],
    ["satyri", "Old Roman woodland deities"],
    ["trysail", "It's attached to a mast in storms"],
    ["starlily", "Rayed wildflower"],
  ]),
  new WordPyramidSolution([
    ["nae", "Scot's disapproval"],
    ["sane", "Not out of one's mind"],
    ["aeons", "Forever and ever in England"],
    ["easeon", "\"___ Down the Road,\" song from \"The Wiz\""],
    ["onebase", "Advancement on a balk"],
    ["openbase", "Space on a diamond with no runner"],
  ]),
  new WordPyramidSolution([
    ["one", "Next to zero"],
    ["done", "Type of deal"],
    ["drone", "Male wasp"],
    ["dinero", "Barcelona bucks"],
    ["redlion", "Figure on the Scottish coat of arms"],
    ["longride", "Cross-country train trip, e.g."],
  ]),
  new WordPyramidSolution([
    ["uts", "French musical notes"],
    ["ruts", "Low spots"],
    ["rusts", "Corrodes, as metal"],
    ["spurts", "Sudden jets"],
    ["purists", "By-the-book types"],
    ["pursuits", "Quests"],
  ]),
  new WordPyramidSolution([
    ["her", "Gender-specific pronoun"],
    ["herd", "Cattle in quantity"],
    ["horde", "Unruly crowd"],
    ["hedron", "Poly- follower"],
    ["dethorn", "Make roses safe to handle"],
    ["onethird", "Asia's share of Earth's landmass, roughly"],
  ]),
  new WordPyramidSolution([
    ["cab", "Airport pickup option"],
    ["scab", "Coagulated wound"],
    ["crabs", "Crustaceans"],
    ["cobras", "Hooded snakes"],
    ["escobar", "Medellín Cartel name"],
    ["boscpear", "Bartlett alternative"],
  ]),
  new WordPyramidSolution([
    ["rob", "Take unlawfully from"],
    ["boar", "Animal that sounds dull"],
    ["abort", "Cancel, NASA-style"],
    ["boater", "Traditional barbershop quartet hat"],
    ["abother", "\"I hate to be ___\""],
    ["hotelbar", "Like a Hyatt feature"],
  ]),
  new WordPyramidSolution([
    ["rat", "Mob informant"],
    ["tsar", "Pre-1917 ruler"],
    ["stair", "Story connector"],
    ["satire", "Genre for The Onion"],
    ["misrate", "Evaluate erroneously"],
    ["airtimes", "TV schedule listings"],
  ]),
  new WordPyramidSolution([
    ["mil", "1,000,000, for short"],
    ["lime", "Gin-and-tonic garnish"],
    ["slime", "Slug evidence"],
    ["smiled", "Wore an upside-down frown"],
    ["misdeal", "Pass out faceup, maybe"],
    ["misleads", "Gives the wrong impression"],
  ]),
  new WordPyramidSolution([
    ["pan", "Utensil for preparing bacon"],
    ["nape", "A collar might hide it"],
    ["alpen", "Pertaining to Swiss ranges"],
    ["planes", "American inventory"],
    ["planers", "Carpentry machines"],
    ["replants", "Sows again"],
  ]),
  new WordPyramidSolution([
    ["sum", "n(n+1)/2, for all integers from 1 to n"],
    ["emus", "Birds with dark green eggs"],
    ["menus", "Waiters' offerings"],
    ["unseam", "Rip apart, as clothing"],
    ["acumens", "Sharp-minded qualities"],
    ["unclesam", "American giving you a pointer?"],
  ]),
  new WordPyramidSolution([
    ["sin", "Envy, wrath or sloth, e.g."],
    ["spin", "Euphemism for political distortion"],
    ["pines", "Loblollies and larches"],
    ["snipes", "Wesley of \"Blade\""],
    ["splines", "Thin construction strips"],
    ["limpness", "Unappealing handshake quality"],
  ]),
  new WordPyramidSolution([
    ["roc", "Mythical bird with giant talons"],
    ["corn", "Ethanol source"],
    ["corns", "Disdain"],
    ["carson", "___ City (Nevada's capital)"],
    ["rancors", "Feelings of malice"],
    ["carrions", "Animal carcasses"],
  ]),
  new WordPyramidSolution([
    ["tab", "A round of drinks may go on one"],
    ["brat", "Unpleasant child"],
    ["rabat", "Capital of French Morocco"],
    ["abater", "One that lessens"],
    ["bearcat", "University of Cincinnati athlete"],
    ["crabmeat", "Stuffed sole stuffing"],
  ]),
  new WordPyramidSolution([
    ["toe", "Sneaker section"],
    ["rote", "Kind of memorization"],
    ["store", "Bloomingdale's or Macy's"],
    ["towers", "Minarets, e.g."],
    ["swearto", "Affirm (on oath)"],
    ["lowrates", "One-star hotel attraction"],
  ]),
  new WordPyramidSolution([
    ["dim", "Like the light from a low-wattage bulb"],
    ["mind", "Something read by a telepath"],
    ["admin", "Online moderator, for short"],
    ["medina", "Saudi Arabian city home to Muhammad's burial place"],
    ["maidens", "Nonwinning racehorses"],
    ["painmeds", "Oxy, Vike and the like"],
  ]),
  new WordPyramidSolution([
    ["est", "Hard ending?"],
    ["tees", "Tops without buttons"],
    ["sheet", "Bed cover that's fit for a king"],
    ["threes", "Basketball shots from beyond the arc"],
    ["sherbet", "Frozen fruit treat"],
    ["thebears", "N.F.L. record-holder for most retired jersey numbers"],
  ]),
  new WordPyramidSolution([
    ["mar", "Damage"],
    ["ream", "Copier stack"],
    ["smear", "Lab-slide material"],
    ["scream", "Sound on a roller coaster"],
    ["scamper", "Scurry"],
    ["compares", "Likens"],
  ]),
  new WordPyramidSolution([
    ["man", "\"Wowie!\""],
    ["mrna", "Genetic-information carrier: abbr."],
    ["ramen", "Japanese bowlful"],
    ["oarmen", "Crew members"],
    ["anymore", "Nowadays"],
    ["eharmony", "\"Get Who Gets You\" dating site"],
  ]),
  new WordPyramidSolution([
    ["ton", "Hauling unit"],
    ["note", "C-sharp, for one"],
    ["tenor", "Certain voice range"],
    ["enroot", "Plant firmly"],
    ["coronet", "Princess topper"],
    ["concerto", "Piece for soloist and orchestra"],
  ]),
  new WordPyramidSolution([
    ["nae", "\"__ wind, __ rain--__ golf!\": Scottish adage"],
    ["sane", "All there mentally"],
    ["anise", "Ingredient in black jelly beans"],
    ["inasec", "\"Almost ready!\""],
    ["arsenic", "Killer in some murder mysteries"],
    ["arcsines", "They range from -1 to 1 in trigonometry"],
  ]),
  new WordPyramidSolution([
    ["nul", "Nonexistent, in Nantes"],
    ["lune", "\"Clair de __\""],
    ["ulnae", "Armbones"],
    ["tulane", "Green Wave school"],
    ["nutmeal", "Low-carb alternative to oats"],
    ["tunamelt", "Diner order"],
  ]),
  new WordPyramidSolution([
    ["ear", "Van Gogh's missing piece?"],
    ["tear", "Shed a ___ (weep)"],
    ["tater", "Idaho product, informally"],
    ["taster", "Employee of a mistrustful monarch"],
    ["trentas", "Large Starbucks orders"],
    ["tartness", "Vinegary quality"],
  ]),
  new WordPyramidSolution([
    ["ten", "Perfect gymnastics score"],
    ["anet", "Without ___ (risky way to work)"],
    ["atune", "What singers carry"],
    ["auntie", "Dorothy's Em, for one"],
    ["antique", "(Something) valuable because of age and quality"],
    ["piquante", "Sauce ___ (interest stimulator)"],
  ]),
  new WordPyramidSolution([
    ["aid", "Give assistance"],
    ["raid", "Pest control product"],
    ["rabid", "Very enthusiastic, as a fan"],
    ["airbed", "Springless mattress"],
    ["beadier", "Smaller and rounder, as eyes"],
    ["beeramid", "Portmanteau for an arrangement of cans in a dorm room, maybe"],
  ]),
  new WordPyramidSolution([
    ["lan", "Networked computers, for short"],
    ["ulna", "It connects to the elbow"],
    ["lunar", "Like 40% of eclipses"],
    ["neural", "Like brain activity"],
    ["granule", "Fine particle"],
    ["flaregun", "Light shooter?"],
  ]),
  new WordPyramidSolution([
    ["ire", "Great displeasure"],
    ["sire", "Kingly title"],
    ["reins", "Symbols of control"],
    ["nosier", "More prying"],
    ["erosion", "Cliff face process"],
    ["moonrise", "Crescent's ascent"],
  ]),
  new WordPyramidSolution([
    ["led", "Kind of bulb or TV"],
    ["deli", "Where to get a hoagie"],
    ["oiled", "Fixed a squeaky wheel"],
    ["coiled", "Like a sleeping snake"],
    ["melodic", "Lilting"],
    ["domicile", "Where you live"],
  ]),
  new WordPyramidSolution([
    ["tie", "Occasional hockey result"],
    ["site", "Word after \"Web\" or \"camp\""],
    ["suite", "Multi-room hotel offering"],
    ["unites", "Combines"],
    ["aunties", "Mommy's sisters"],
    ["antiques", "Heirlooms, often"],
  ]),
  new WordPyramidSolution([
    ["ate", "Had some pad thai"],
    ["date", "Prom partner"],
    ["cadet", "Space ___ (flaky person)"],
    ["decant", "Get ready to serve wine"],
    ["enacted", "Passed into law"],
    ["cantered", "Rode horseback, in a way"],
  ]),
  new WordPyramidSolution([
    ["gin", "A martini base"],
    ["sing", "Croon"],
    ["sting", "Police operation"],
    ["giants", "Baseball team originally named the Gothams"],
    ["seating", "Party planner's concern"],
    ["steaming", "Misting up"],
  ]),
  new WordPyramidSolution([
    ["san", "Word with \"Diego\" or \"Antonio\""],
    ["ands", "& & &"],
    ["damns", "Sends to hell"],
    ["amends", "Alters, as a bill"],
    ["madness", "Folly"],
    ["medusans", "Jellyfish"],
  ]),
  new WordPyramidSolution([
    ["fir", "Conifer tree"],
    ["fire", "___ ants"],
    ["fried", "Overexposed to the sun"],
    ["finder", "Detective, essentially"],
    ["definer", "Noah Webster, for one"],
    ["friended", "Admitted into one's social network"],
  ]),
  new WordPyramidSolution([
    ["art", "\"... wherefore ___ thou Romeo?\""],
    ["star", "Have the lead part"],
    ["roast", "Center of a luau"],
    ["actors", "Harrison and Ford"],
    ["cartons", "Egg containers"],
    ["castiron", "Skillet makeup"],
  ]),
  new WordPyramidSolution([
    ["set", "Several reps"],
    ["rest", "Doctor's suggestion, at times"],
    ["terse", "Pointed"],
    ["steers", "Controls the wheel"],
    ["secrets", "Confidential things"],
    ["securest", "Most safe"],
  ]),
  new WordPyramidSolution([
    ["ear", "Distinctive Vulcan feature"],
    ["rate", "Charge with a time component"],
    ["stare", "Look too long"],
    ["caters", "Prepares fare for affairs"],
    ["scatter", "Disperse"],
    ["detracts", "Takes something desirable away"],
  ]),
  new WordPyramidSolution([
    ["ese", "\"Vietnam\" suffix"],
    ["seed", "Bit in an apple core"],
    ["eased", "Relaxed, as rules"],
    ["erased", "Removed all evidence of"],
    ["leaders", "They're followed"],
    ["released", "Free"],
  ]),
  new WordPyramidSolution([
    ["sen", "State's rep."],
    ["cone", "Receptor cell sensitive to color"],
    ["scone", "Pastry at a tea party"],
    ["nonces", "Present times"],
    ["nocents", "What two zeroes after a dot may mean"],
    ["convents", "Sisters' accommodations"],
  ]),
];
